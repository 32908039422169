import React from 'react';
import { ItemElement } from './ItemElement';
import { VariantItem } from '../../../../../helpers/types';

type Props = {
    items: VariantItem[];
};

export const ItemsList: React.FC<Props> = ({ items }) => (
    <>
        {items.map((item, index) => (
            <ItemElement key={`itemList-${index}`} item={item} index={index} />
        ))}
    </>
);
