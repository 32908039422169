/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Col, Row } from 'antd';
import { useMediaQuery } from '@react-hook/media-query';
import { css } from '@emotion/react';

import { horizontalMark } from 'src/style/styles';
import { fontSizes, colors, wideScreenBreakpoint } from 'src/constants/variables';
import { styles as elementStyles } from 'src/components/FormElements';
import { AsyncResourceHandler } from 'src/modules/app/AsyncResourceHandler';

import { ImpressionsCarousel } from 'src/modules/productsCatalog/components/ImpressionsCarousel';
import {
    getCollectionItems,
    getCollectionLoadingError,
    getCollectionLoadingStatus,
} from 'src/modules/productsList/store/productCollectionItemsSelectors';
import { fetchCollectionItemsList } from 'src/modules/productsList/store/productCollectionItemsActions';

import { ProductCard } from './ProductCard';
import { JsonObject } from 'src/helpers/types';
import { beautifyName, transformCatalogImageUrl } from '../../../../helpers/utils';

type Props = {
    collection: ProductCollection | null;
    selectedProducts: CollectionItem[];
    setSelectedProducts: (items: CollectionItem[]) => void;
    addToSelectedProducts: (item: CollectionItem) => void;
    collectionImpressions: JsonObject;
    getItemLink?: (item: CollectionItem) => string;
};

export const ProductsList: React.FC<Props> = ({
    collection,
    selectedProducts,
    setSelectedProducts,
    addToSelectedProducts,
    collectionImpressions,
    getItemLink,
}) => {
    const isWide = useMediaQuery('only screen and (min-width: ' + wideScreenBreakpoint);

    const dispatch = useDispatch();
    const collectionItems = useSelector(getCollectionItems)?.map(collectionItem => {
        const newCollectionItem = collectionItem;
        newCollectionItem.colors.map(color => {
            const newColor = color;
            newColor.imageLink = transformCatalogImageUrl(color.imageLink);

            return newColor;
        });

        return newCollectionItem;
    }) || null;

    const error = useSelector(getCollectionLoadingError);
    const loading = useSelector(getCollectionLoadingStatus);

    useEffect(() => {
        const collectionIdAvailable = !!collection?.id;

        if (collectionIdAvailable && collection) {
            dispatch(fetchCollectionItemsList(collection.id));
        }
    }, [collection, dispatch]);

    const checkAll = (items: CollectionItem[]) => {
        selectedProducts.length !== items.length
            ? setSelectedProducts(items)
            : setSelectedProducts([]);
    };

    return (
        <AsyncResourceHandler
            data={{ collection, collectionItems }}
            loading={loading}
            error={error}
            errorDetails={{ status: 404, title: 'Collection Data Not Found' }}
        >
            {(resources) => (
                <React.Fragment>
                    <Row justify="space-between" css={styles.headerRow}>
                        <Col css={styles.collectionName} data-test={'collectionName'}>
                            {resources.collection.name}
                        </Col>
                        <Col css={styles.checkAll} data-test={'checkAllItems'}>
                            <Checkbox
                                css={styles.checkbox}
                                onClick={() => checkAll(resources.collectionItems)}
                                checked={
                                    resources.collectionItems.length !== 0 && selectedProducts.length === resources.collectionItems.length
                                }
                                data-test={'checkAllItemsCheckbox'}
                            >
                                alles auswählen
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        {!isWide && (
                            <Col span={12} lg={6}>
                                <ImpressionsCarousel
                                    collectionImpressions={collectionImpressions}
                                />
                            </Col>
                        )}
                        {resources.collectionItems.map((item) => (
                            <Col
                                key={item.modelId}
                                span={12}
                                lg={6}
                                data-test={`${beautifyName(item.modelName)}_PRODUCT_ITEM`}
                            >
                            <ProductCard
                                defaultVariant={item}
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                addToSelectedProducts={addToSelectedProducts}
                                getDetailsLink={getItemLink}
                            />
                            </Col>
                        ))}
                    </Row>
                </React.Fragment>
            )}
        </AsyncResourceHandler>
    );
};

const styles = {
    headerRow: css`
        padding: 0 0 13px 0;
    `,
    fullHeight: css`
        height: 100%;
    `,
    collectionName: css`
        line-height: 1;
        font-family: Gebauer-Regular, sans-serif;
        font-size: ${fontSizes.medium};
        text-transform: uppercase;

        padding-top: 10px;

        ${horizontalMark(css`
            background-color: ${colors.backgroundDark};
        `)}
    `,
    checkbox: css`
        ${elementStyles.checkboxItem};

        && .ant-checkbox {
            width: 22px;
            height: 22px;
            padding: 2px;
            border-radius: 5px;
            border-color: ${colors.fontPrimaryDark};
            float: right;
        }

        && .ant-checkbox + span {
            padding-right: 12px;
        }

        && .ant-checkbox-inner {
            width: 16px;
            height: 16px;
            border-radius: 3px;
        }
    `,
    checkAll: css`
        right: 10px;
        height: 40px;
        padding-top: 10px;
    `,
};
