export const DEFAULT_AMOUNT_OF_WEARERS = 1;

export const MAX_PRICE_VALUE = 999999999.99;

export const DEFAULT_OFFER_SERVICE_PRICE = 0.41;

export const DEFAULT_OFFER_KMU_INTRO =
    'wir freuen uns über Ihr Interesse an unserem Rundum-Service für Berufs- und Schutzkleidung und\n' +
    'unterbreiten Ihnen, wie mit Herrn XYZ besprochen, wunschgemäß nachstehendes Angebot.\n' +
    '\n' +
    'Wir bitten Sie, das persönlich für Sie erstellte Angebot vertraulich zu behandeln. Bei Fragen können Sie\n' +
    'sich gerne an Frau/Herrn XYZ per E-Mail XYZ oder telefonisch unter XYZ wenden.\n' +
    '\n' +
    'Freundliche Grüße\n' +
    '\n' +
    'MEWA SE & Co. Vertrieb OHG\n' +
    'Verkaufsleitung H&I XYZ\n' +
    '\n' +
    'i.V. XYZ';

export const DEFAULT_OFFER_KMU_OUTRO =
    'Das für Sie erstellte Angebot basiert auf einer Anzahl von XYZ auszustattenden Mitarbeitenden.\n' +
    '\n' +
    'Vorsorglich weisen wir darauf hin, dass Mewa gemäß der geltenden Mewa Vertrags- und\n' +
    'Ankaufbedingungen bei Vertragsende, den Ankauf der Textilien (Kleidung, Embleme ggf. Zusatzartikel)\n' +
    'verlangen kann.\n' +
    '\n' +
    'Die Berechnung der Leistung von Mewa erfolgt monatlich. In den Preisen sind Betriebsferien, Urlaub,\n' +
    'Krankheit etc. bereits berücksichtigt. Die Mindestvertragslaufzeit beträgt 3 volle Kalenderjahre ab\n' +
    'Erstauslieferung.\n' +
    '\n' +
    'Das Angebot gilt unter Einbezug unserer Vertrags- und Ankaufbedingungen, die wir Ihnen gern auf\n' +
    'Anfrage zusenden.\n' +
    '\n' +
    'Mewa SE & Co. Vertrieb OHG\n' +
    'Verkaufsleitung H&I XYZ\n' +
    'XYZ, den XYZ';

export const DEFAULT_OFFER_KMU_HINTS = undefined;
