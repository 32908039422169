import { VariantItem } from '../../../helpers/types';

export const createNewOfferItemDetailsFromCartResponse = (
    item: VariantItem,
    response: VariantItem,
) => {
    const newItem = Object.assign(item);
    const newResponse = Object.assign(response);
    
    for (const key in newResponse) {
        if (key in newItem) {
            newItem[key] = newResponse[key];
        }
    }

    return newItem;
};
