/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { dropdownChevronStyles } from 'src/style/styles';
import { colors, fontSizes, shadows } from 'src/constants/variables';

import { ColorMark } from './ColorMark';

type ColorChooserDropdownProps = {
    colors: ProductColor[];
    onColorChange: (color: ProductColor) => void;
    warning: boolean;
};

export const ColorChooserDropdown: React.FC<ColorChooserDropdownProps> = ({
    colors,
    onColorChange,
    warning,
}) => {
    const [selectedColor, setSelectedColor] = useState<string | undefined>();

    const getMenuItems = () =>
        colors.map((color) => (
            <Menu.Item
                css={styles.item}
                onClick={() => {
                    setSelectedColor(color.key);
                    onColorChange(color);
                }}
                key={color.key}
            >
                <ColorMark
                    key={color.key}
                    color={color}
                    selected={selectedColor === color.key}
                    size={1}
                    css={styles.colorCircle}
                />
            </Menu.Item>
        ));

    return (
        <Dropdown
            css={styles.wrapper}
            overlay={<Menu css={styles.list}>{getMenuItems()}</Menu>}
            trigger={['click']}
            onVisibleChange={() => {
                setSelectedColor(undefined);
            }}
            getPopupContainer={(trigger) => trigger ?? document.body}
        >
            <div css={[styles.title, styles.font]} data-test={'colorChooserDropdown'}>
                <span>FARBE AUSWÄHLEN</span>
                {warning && (
                    <div css={styles.warningContainer}>
                        <div css={styles.warningDot} />
                    </div>
                )}
                <DownOutlined />
            </div>
        </Dropdown>
    );
};

const styles = {
    colorCircle: css`
        pointer-events: auto;
    `,
    wrapper: css`
        width: 100%;
        border-top: 1px solid ${colors.secondaryDark};
        position: relative;
        cursor: pointer;
        ${dropdownChevronStyles};
    `,
    font: css`
        text-transform: uppercase;
        font-size: ${fontSizes.mSmall};
        font-family: Gebauer-Regular, sans-serif;
    `,
    title: css`
        color: ${colors.fontHeader};
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            color: ${colors.secondary};
        }
    `,
    list: css`
        position: absolute;
        margin-top: 8px;
        width: 100%;
        min-height: 50px;
        background-color: ${colors.primary};
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        border-radius: 5px;
        box-shadow: ${shadows.card};
    `,
    item: css`
        padding: 16px 32px;
        color: ${colors.secondary};
    `,
    warningContainer: css`
        flex-grow: 0.9;
        align-self: baseline;
    `,
    warningDot: css`
        width: 8px;
        height: 8px;
        margin: 5px;
        background-color: ${colors.warningDot};
        border-radius: 50px;
        float: right;
    `,
};
