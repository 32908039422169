import moment, { MomentInput } from 'moment';

export function noop() {
    return undefined;
}

export function beautifyName(name: string): string {
    // replaceAll is not supported by CI pipeline (due to Node version < 15),
    // thus we're using RegEx to remove whitespaces
    return name?.replace(/\s/g, '_').toUpperCase();
}

export function getDecimalSeparator(separator?: string) {
    return (
        separator ??
        Number.parseFloat('1.23')
            .toLocaleString()
            .replace(/.*1(.*)2.*/, '$1')
    );
}

export function delay(ms = 500) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function utcToLocalFormat(input?: MomentInput, format?: string): string {
    return moment.utc(input).local().format(format);
}

export function parseToPrice(value: number, decimal?: string) {
    const price = (Math.round(value * 100) / 100).toFixed(2);

    return price.replace('.', getDecimalSeparator(decimal));
}

export function transformCatalogImageUrl(url?: string | null, width = 630, isImf?: boolean) {
    // IMF is subdomain where images are held
    const transformedUrl = (url ?? '')
        .replace(/\/none\//, `/f,j/s,y,${width}/`)
        .replace(/\..{3,4}$/, '.jpg');
    return isImf ? transformedUrl.replace('www', 'imf') : transformedUrl;
}

export function defaultRotationPrice(rotationRates: CartRotationRate[], selectedRotation: string) {
    return rotationRates.find(({ rotation }) => rotation === selectedRotation)?.rotationFee;
}

export const splitText = (text: string, maxLength: number): [string, string] => {
    if (text.length <= maxLength) {
        return [text, ''];
    }

    const splitCharacters = [' ', '.', ',', ';', ':', '!', '?', '\n', '\t'];

    let splitIndex = -1;
    for (const char of splitCharacters) {
        const index = text.lastIndexOf(char, maxLength);
        if (index > splitIndex) {
            splitIndex = index;
        }
    }

    if (splitIndex === -1) {
        splitIndex = maxLength;
    }

    const part1 = text.slice(0, splitIndex).trim();
    const part2 = text.slice(splitIndex).trim();

    return [part1, part2];
};
