/** @jsxImportSource @emotion/react */
import React from 'react';

import { css } from '@emotion/react';
import { MBKServiceItem } from './MBKServiceItem';
import { additionalServiceData } from './index';
import { FormInstance } from 'antd';
import { OfferFormProps } from '../../types';

export type MBKServicesProps = {
    form: FormInstance<OfferFormProps>;
};

export const MBKServices: React.FC<MBKServicesProps> = ({ form }) => (
    <div>
        <h2 css={styles.heading}>MBK Services</h2>

        {
            additionalServiceData.map((additionalService, index) =>
                <MBKServiceItem key={index} serviceData={additionalService} form={form} />
            )
        }
    </div>
);

const styles = {
    heading: css`
        padding-top: 32px;
        padding-bottom: 4px;
    `
};
