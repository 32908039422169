/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { css } from '@emotion/react';

import { CustomModal } from 'src/components';
import { AsyncResourceHandler } from 'src/modules/app';
import { utcToLocalFormat } from 'src/helpers/utils';
import { colors } from 'src/constants/variables';

import { Header } from './Header';
import { ItemsArea } from './ItemsArea';
import { ContentHeader } from './ContentHeader';
import { MarketingDetailsReadonly } from './MainSectionReadonly';
import { OfferPreviewDetails } from './OfferPreviewDetails';
import { fetchOfferDetails } from '../../service/offerServices';
import { AdditionalItem, VariantItem } from '../../../../helpers/types';
import { AdditionalItemsArea } from './ItemsArea/AdditionalItemsArea';
import AdditionalService from "../../../../assets/pdf/articlePage/AdditionalService.png";
import { LegalInformation } from './LegalInformation';
import GarmentCover from '../../../../assets/pdf/articlePage/garmentCover.png';
import ClothingStand from '../../../../assets/pdf/articlePage/clothingStand.png';

type OfferOverview = {
    offerItems: VariantItem[];
    offerBasics: OfferBasics;
    offerDetails: OfferDetails;
};

type Props = {
    offerSummary?: OfferPreviewSummary;
    onClickApproveOffer?: (offerId: OfferSummary['id']) => Promise<void>;
    onClick: () => void;
};

export const OfferPreviewModal: React.FC<Props> = ({ offerSummary, onClick }) => {
    const [offerOverview, setOfferOverview] = useState<OfferOverview | null>(null);
    const [hasError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (offerSummary?.id) {
            setError(false);
            setLoading(true);
            fetchOfferDetails(offerSummary.id)
                .then((response) => {
                    setOfferOverview(response);
                    setLoading(false);
                })
                .catch(() => {
                    setError(true);
                    setLoading(false);
                });
        }
    }, [offerSummary]);

    return (
        <CustomModal
            onClick={onClick}
            visible={!!offerSummary?.id}
            destroyOnClose
            modalCss={styles.modal}
        >
            <AsyncResourceHandler data={{ ...offerOverview }} error={hasError} loading={isLoading}>
                {({ offerBasics, offerDetails, offerItems }) => {
                    const getServiceById = (id: number) => offerBasics?.wardrobeOptions.find((el) => el.id === id && el);

                    const additionalItems: AdditionalItem[] = offerDetails?.wardrobeOptionsData.map(service => ({
                        mainHeading: getServiceById(service.id)!.label,
                        image: AdditionalService,
                        subHeading: 'Je einzukleidender Person pro Woche',
                        amountOfWearers: offerDetails?.amountOfWearers,
                        pricePerWearer: service.price,
                    }));

                    if (offerDetails?.garmentCover) {
                        additionalItems.push({
                            mainHeading: "Kleiderhüllen",
                            image: GarmentCover,
                            amountOfWearers: offerDetails?.garmentAmountOfWearers,
                            basePrice: offerDetails?.garmentPrice,
                            pricePerWearer: offerDetails?.garmentPrice,
                        });
                    }

                    if (offerDetails?.clothingStand) {
                        additionalItems.push({
                            mainHeading: "Konfektionsrollständer",
                            image: ClothingStand,
                            subHeading: "Preis pro Woche",
                            amountOfWearers: offerDetails?.clothingAmountOfWearers,
                            basePrice: offerDetails?.clothingPrice,
                        });
                    }

                    return <>
                        <Header
                            onClick={onClick}
                            offerName={offerDetails.name}
                            creationDate={utcToLocalFormat(offerSummary?.createAt, 'DD.MM.YYYY')}
                            creationTime={utcToLocalFormat(offerSummary?.createAt, 'HH:mm:ss')}
                        />
                        <div css={styles.content}>
                            <ContentHeader />
                            <MarketingDetailsReadonly
                                customerCompany={offerBasics.customerCompany}
                                customerContact={offerBasics.customerContact}
                                employeeContact={offerBasics.employeeContact}
                                selectedDate={moment(offerDetails.validUntil)}
                                selectedBackground={offerDetails.backgroundImageUri}
                            />
                            <div css={styles.wrapper}>
                                <ItemsArea items={offerItems} />

                                {
                                    additionalItems && additionalItems.length > 0 &&
                                    <AdditionalItemsArea items={additionalItems} startingPos={offerItems.length} />
                                }
                            </div>

                            <OfferPreviewDetails
                                details={offerDetails}
                                services={offerBasics.wardrobeOptions}
                            />

                            <LegalInformation />
                        </div>
                    </>;
                }}
            </AsyncResourceHandler>
        </CustomModal>
    );
};

const styles = {
    modal: css`
        .ant-modal-content {
            padding: 0 !important;
        }

        .ant-modal-body {
            padding: 0 !important;
        }

        .ant-modal-close {
            display: none;
        }
    `,
    content: css`
        background-color: ${colors.primary};
    `,
    wrapper: css`
        padding: 90px 32px 32px;
    `,
    buttonWrapper: css`
        padding: 0 32px 32px;
    `,
};
