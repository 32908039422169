import { OfferFormItem, OfferFormItems } from '../components/types';

const DEFAULT_AMOUNT_OF_WEARERS = 1;
export const DEFAULT_LOGO_PRICE = 0;

export const calculatePricePerItem = ({
    basePrice,
    labelPrice = DEFAULT_LOGO_PRICE,
    logoBackPrice = DEFAULT_LOGO_PRICE,
    logoFrontPrice = DEFAULT_LOGO_PRICE,
    itemsPerWearer,
}: OfferFormItem): number | undefined =>
    basePrice
        ? (basePrice + logoBackPrice + logoFrontPrice + labelPrice) *
          (itemsPerWearer || DEFAULT_AMOUNT_OF_WEARERS)
        : undefined;

export const calculateTotalPricePerItem = ({
    basePrice,
    labelPrice = DEFAULT_LOGO_PRICE,
    logoBackPrice = DEFAULT_LOGO_PRICE,
    logoFrontPrice = DEFAULT_LOGO_PRICE,
    itemsPerWearer,
    amountOfWearers = DEFAULT_AMOUNT_OF_WEARERS,
}: OfferFormItem): number | undefined =>
    basePrice
        ? (basePrice + logoBackPrice + logoFrontPrice + labelPrice) *
          (itemsPerWearer || DEFAULT_AMOUNT_OF_WEARERS) *
          amountOfWearers
        : undefined;

export const calculateTotalPricePerWeek = (
    items: OfferFormItems,
    totalNumberOfWearers: number,
    deliveryPrice: number,
    selectedServices: number[],
    services: { [key: string]: number },
    mbkServicesFee: number,
): number => {
    const itemsPrice = Object.keys(items).reduce(
        (prev, currentItemId) => prev + (calculateTotalPricePerItem(items[currentItemId]) || 0),
        0,
    );

    const servicesPrice = prepareServicePrice(selectedServices, services);

    return itemsPrice + totalNumberOfWearers * servicesPrice + deliveryPrice + mbkServicesFee;
};

export const calculateTotalWeeklyPriceAllItems = (
    items: OfferFormItems,
    selectedServices: number[],
    services: { [key: string]: number },
): number => {
    const itemsPrice = Object.keys(items).reduce(
        (prev, currentItemId) => prev + (calculateTotalPricePerItem(items[currentItemId]) || 0),
        0,
    );

    const servicesPrice = prepareServicePrice(selectedServices, services);

    return itemsPrice + servicesPrice;
};

const prepareServicePrice = (
    selectedServices: number[],
    services: { [key: string]: number } = {},
) => (selectedServices.length ? selectedServices.reduce((prev, id) => prev + services[id], 0) : 0);
