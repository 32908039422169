/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';

import Logo from 'src/assets/images/logo/mewa-logo_red.png';
import { mobileScreenStyles } from 'src/style/styles';
import { colors } from 'src/constants/variables';
import { getDateWithYearLabel } from '../../helpers/date';

type Props = {
    readOnly: boolean;
    customCss?: SerializedStyles;
};

export const BrandHeaderLogo: React.FC<Props> = ({ readOnly, customCss }) => (
    <div css={[logoBrandHeaderStyles.topRow, customCss]}>
        {readOnly ? (
            <a href="https://www.mewa.de/">
                <img src={Logo} alt="logo" css={logoBrandHeaderStyles.logo} data-test={'brandHeaderLogo'} />
            </a>
        ) : (
            <img src={Logo} alt="logo" css={logoBrandHeaderStyles.logo} data-test={'brandHeaderLogo'} />
        )}
        <div css={logoBrandHeaderStyles.date} data-test={'brandHeaderDate'}>{getDateWithYearLabel()}</div>
    </div>
);

export const logoBrandHeaderStyles = {
    topRow: css`
        height: 124px;
        padding-bottom: 17px;
        padding-right: 32px;
        padding-left: 46px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        ${mobileScreenStyles(css`
            padding-right: 16px;
            padding-left: 16px;
            padding-top: 8px;
        `)};
    `,
    date: css`
        color: ${colors.fontPrimaryDark};
        text-transform: uppercase;
        font-family: 'Gebauer-Regular', sans-serif;
    `,
    logo: css`
        width: 150px;

        ${mobileScreenStyles(css`
            align-self: flex-start;
        `)};
    `,
    link: css`
        text-align: right;
        padding-right: 32px;
        justify-content: flex-end;
    `,
};
