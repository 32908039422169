import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { colors } from '../../../constants/variables';

const styles = StyleSheet.create({
    text: {
        fontSize: '8px',
        color: colors.primaryDark,
        bottom: '25px',
        left: '40px',
        position: 'absolute',
    },

    line: {
        paddingTop: '4px',
    },
});

export const LegalInformation: React.FC = () => (
    <View style={styles.text}>
        <Text style={styles.line}>
            Die Preise basieren auf den hier angegebenen Mengen und gelten zzgl. MwSt.
        </Text>

        <Text style={styles.line}>
            Individualisierte Ware ist von Umtausch/Rückgabe ausgeschlossen
        </Text>
    </View>
);
