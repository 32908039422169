import { Document } from '@react-pdf/renderer';
import { OfferForPdf } from '../types';

import { FirstIntroPage } from 'src/modules/offerPdf/templates/pdfPages/firstIntroPage';
import { ProductListPage } from 'src/modules/offerPdf/templates/pdfPages/productListPage/productListPage';
import { OutroPage } from 'src/modules/offerPdf/templates/pdfPages/outroPage';
import { DeliverySchemePage } from './pdfPages/deliverySchemePage';
import { DELIVERY_14T, DELIVERY_7T } from './pdfPages/delivery/deliveryOptionsData';
import { SecondIntroAndOutroPage } from './pdfPages/secondIntroAndOutroPage';
import { splitText } from '../../../helpers/utils';

const INTRO_CH_LIMIT = 550;
const OUTRO_CH_LIMIT = 1230;

export function splitIntro(offerOverview: OfferForPdf): [string, string] {
    const introText = offerOverview?.details?.kmuDetails?.intro || '';
    const comments = offerOverview?.basics?.contactComments || '';
    const fullIntroText = comments !== '' ? introText + "\n\nAnmerkung:\n" + comments : introText;

    return splitText(fullIntroText, INTRO_CH_LIMIT);
}

export function offerPdfFirstPage(offerOverview: OfferForPdf) {
    const [firstPageText, secondPageText] = splitIntro(offerOverview);

    return (
        <Document>
            <FirstIntroPage offer={offerOverview} text={firstPageText} />
            {secondPageText !== '' && <SecondIntroAndOutroPage text={secondPageText} pageOffset={0} />}
        </Document>
    )
}

export function offerPdfLastPages(offerOverview: OfferForPdf) {
    const delivery= offerOverview?.details?.rotation === '14T' ? DELIVERY_14T : DELIVERY_7T;

    const outroText = offerOverview?.details?.kmuDetails?.outro || '';
    const [firstPageText, secondPageText] = splitText(outroText, OUTRO_CH_LIMIT);

    const pageOffset = splitIntro(offerOverview)[1] === '' ? 2 : 3;

    return (
        <Document>
            {offerOverview && (
                <ProductListPage
                    itemList={offerOverview.items}
                    details={offerOverview.details}
                    basics={offerOverview.basics}
                    services={offerOverview.details?.wardrobeOptionsData}
                    displayCategory={offerOverview.displayCategory}
                    pageOffset={pageOffset}
                />
            )}
            <DeliverySchemePage
                delivery={delivery}
                hints={offerOverview?.details?.kmuDetails?.hints}
                pageOffset={pageOffset}
            />
            <OutroPage text={firstPageText} pageOffset={pageOffset} />
            {secondPageText !== '' && <SecondIntroAndOutroPage text={secondPageText} pageOffset={pageOffset} />}
        </Document>
    )
}