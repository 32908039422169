import React from 'react';
import { Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PageNumber } from '../../components/pageNumber';

type Props = {
    text: string;
    pageOffset: number;
};

export const SecondIntroAndOutroPage: React.FC<Props> = ({ text, pageOffset }) => {
    // Disable hyphenation for the whole pdf document to prevent hyphenate people's names.
    Font.registerHyphenationCallback((aWord) => [aWord]);

    const styles = StyleSheet.create({
        page: {
            fontFamily: 'GebauerRegular',
            padding: '40px 40px 32px 64px',
        },
        section: {
            position: 'relative',
            minHeight: '100%',
            height: '100%',
        },
        text: {
            fontFamily: 'GebauerRegular',
            fontSize: '11px',
        }
    });

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text style={styles.text}>{text.split('')}</Text>
            </View>

            <PageNumber offset={pageOffset} />
        </Page>
    );
};
