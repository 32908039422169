/** @jsxImportSource @emotion/react */
import React from 'react';
import { Collapse } from 'antd';
import { css } from '@emotion/react';

import { colors, fontSizes } from 'src/constants/variables';
import { CollapsePanelProps } from 'antd/lib/collapse';

export const CollapsePanel: React.FC<CollapsePanelProps> = ({ children, ...rest }) => (
    <Collapse.Panel css={panel} {...rest}>
        {children}
    </Collapse.Panel>
);

const panel = css`
    && {
        padding: 12px 0;
        border-top: 1px solid ${colors.secondaryDark};

        :not(:last-of-type) {
            border-bottom: none;
        }
    }

    && .ant-collapse-header {
        color: ${colors.fontSecondary};
        font-family: Gebauer-Regular, sans-serif;
        font-size: ${fontSizes.medium};
        text-transform: uppercase;
        padding-left: 48px;
    }

    &&& .ant-collapse-content-box {
        padding: 0;
        color: ${colors.fontPrimaryDark};
    }
`;
