import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { colors } from 'src/constants/variables';
import { parseToPrice } from 'src/helpers/utils';
import { AdditionalItem } from '../../../../../helpers/types';

const styles = StyleSheet.create({
    productListTableItem: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        minHeight: '40px',
        borderBottom: `0.5px solid ${colors.secondaryDark}`,
    },
    productImageWrapper: {
        position: 'relative',
        padding: '12px 0 10px',
        width: '30px',
    },
    productImage: {
        width: '30px',
    },
    productDetails: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        fontSize: '6px',
        padding: '6px 10px 5px 12px',
        width: '150px',
    },
    productListTableCol34: {
        flexBasis: '72.5%',
        maxWidth: '72.5%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    productListNormalTableCol: {
        flexBasis: 'calc(10.5% - 4px)',
        maxWidth: 'calc(10.5% - 4px)',
        padding: '19px 0 2px',
        fontSize: '6.5px',
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingBottom: '20px',
    },
    productListTableCol1: {
        flexBasis: 'calc(5% - 4px)',
        maxWidth: 'calc(5% - 4px)',
        padding: '19px 0 2px 3px',
        fontSize: '6.5px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingBottom: '20px',
    },
    mediumText: {
        fontFamily: 'GebauerMedium',
    },
    mainHeading: {
        fontFamily: 'GebauerMedium',
        fontSize: '8px',
    },
    subHeading: {
        fontSize: '6px',
        paddingTop: '1px',
    }
});

type Props = {
    item: AdditionalItem;
    pos: number;
};

const COMMA = ',';

export const AdditionalArticleItem: React.FC<Props> = ({ item, pos }) => {
    return (
        <View wrap={false} style={styles.productListTableItem}>
            <View style={styles.productListTableCol1}>
                <Text>{pos}</Text>
            </View>

            <View style={styles.productListTableCol34}>
                <View style={styles.productImageWrapper}>
                    <Image style={styles.productImage} src={item.image} />
                </View>

                {
                    <View style={styles.productDetails}>
                        <Text style={styles.mainHeading}>
                            {item.mainHeading}
                        </Text>

                        {
                            item?.subHeading &&
                            <Text style={styles.subHeading}>
                                {item.subHeading}
                            </Text>
                        }
                    </View>
                }
            </View>

            <View style={styles.productListNormalTableCol}>
                <Text>
                    {
                        item?.amountOfWearers !== null && item.amountOfWearers ? item.amountOfWearers : '-'
                    }
                </Text>
            </View>

            <View style={styles.productListNormalTableCol}>
                <Text>
                    {item?.basePrice ? `${parseToPrice(item.basePrice!, COMMA)} €` : '-'}
                </Text>
            </View>

            <View style={{ ...styles.productListNormalTableCol, paddingRight: '2px' }}>
                <Text style={styles.mediumText}>
                    {item?.pricePerWearer ? `${parseToPrice(item.pricePerWearer!, COMMA)} €` : '-'}
                </Text>
            </View>
        </View>
    );
};
