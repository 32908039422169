import React from 'react';
import moment from 'moment';
import { Image, Page, StyleSheet, Text } from '@react-pdf/renderer';
import { colors } from 'src/constants/variables';
import { ProductList } from 'src/modules/offerPdf/templates/pdfPages/productListPage/productList';

import { PageNumber } from 'src/modules/offerPdf/components/pageNumber';
import { pageStyles } from 'src/modules/offerPdf/components/pageTitle';
import { AdditionalItem, VariantItem } from '../../../../../helpers/types';
import { LegalInformation } from 'src/modules/offerPdf/components/legalInformation';
import MewaLogoRed from '../../../../../assets/images/logo/mewa-logo_red.png';
import { AdditionalArticlesList } from './additionalArticlesList';
import AdditionalService from '../../../../../assets/pdf/articlePage/AdditionalService.png';
import ClothingStand from '../../../../../assets/pdf/articlePage/clothingStand.png';
import GarmentCover from '../../../../../assets/pdf/articlePage/garmentCover.png';
import { ProductListPageSummary } from './productListPageSummary';

const styles = StyleSheet.create({
    mainTitle: {
        fontSize: '16px',
        fontFamily: 'GebauerBold',
        color: `${colors.darkRed}`,
    },
    secondaryTitle: {
        fontSize: '10px',
        fontFamily: 'GebauerRegular',
    },
    logoPic: {
        width: '214.67px',
        height: '40px',
        marginBottom: '30px',
        top: '-4px',
    },
    pageNumberAndTotal: {
        position: 'absolute',
        left: '518px',
        fontFamily: 'GebauerRegular',
        fontSize: '6.5px',
    },
});

type Props = {
    itemList: VariantItem[];
    details: OfferDetails | undefined;
    basics: OfferBasics | undefined;
    services: WardrobeOptionData[] | undefined;
    displayCategory: boolean;
    pageOffset: number;
};

const RAW_DATE_FORMAT = 'DD.MM.YYYY';

export const ProductListPage: React.FC<Props> = ({
    itemList,
    details,
    basics,
    services,
    displayCategory,
    pageOffset
}) => {
    const getServiceById = (id: number) => basics?.wardrobeOptions.find((el) => el.id === id && el);

    const additionalItems: AdditionalItem[] = services?.map(service => (
        {
            mainHeading: getServiceById(service.id)!.label,
            image: AdditionalService,
            subHeading: 'Je einzukleidender Person pro Woche',
            amountOfWearers: details?.amountOfWearers,
            pricePerWearer: service.price,
        }
    )) || [];

    if (details?.garmentCover) {
        additionalItems.push({
            mainHeading: "Kleiderhüllen",
            image: GarmentCover,
            amountOfWearers: details?.garmentAmountOfWearers,
            basePrice: details?.garmentPrice,
            pricePerWearer: details?.garmentPrice,
        });
    }

    if (details?.clothingStand) {
        additionalItems.push({
            mainHeading: "Konfektionsrollständer",
            image: ClothingStand,
            subHeading: "Preis pro Woche",
            amountOfWearers: details?.clothingAmountOfWearers,
            basePrice: details?.clothingPrice,
        });
    }

    return (
        <Page size="A4" style={pageStyles.page}>
            <Image style={styles.logoPic} src={MewaLogoRed} />

            <Text
                style={{
                    ...styles.pageNumberAndTotal,
                    top: '26x',
                    marginBottom: '2px',
                }}

                render={({ pageNumber, totalPages }) => (
                    `Seite ${pageNumber} von ${totalPages - pageOffset + 1}`
                )}

                fixed
            />

            <Text style={styles.mainTitle}>Ihre Artikelauswahl</Text>

            <Text style={styles.secondaryTitle}>
                Unser individuelles Angebot für Sie - gültig bis{' '}
                {moment(details?.validUntil).format(RAW_DATE_FORMAT)}
            </Text>

            <ProductList
                itemList={itemList}
                displayCategory={displayCategory}
            />

            {
                additionalItems && additionalItems.length > 0 &&
                <AdditionalArticlesList items={additionalItems} startingPos={itemList.length + 1} />
            }

            <ProductListPageSummary details={details} />

            <LegalInformation />

            <Text
                style={{
                    ...styles.pageNumberAndTotal,
                    bottom: '38px',
                }}

                render={({ pageNumber, totalPages }) => (
                    `Seite ${pageNumber} von ${totalPages - pageOffset + 1}`
                )}

                fixed
            />

            <PageNumber offset={pageOffset} />
        </Page>
    );
}
