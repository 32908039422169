/** @jsxImportSource @emotion/react */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import Icon from '@ant-design/icons';

import { User } from 'src/assets/icons';
import { colors, fontSizes, shadows } from 'src/constants/variables';
import { getCurrentUserName } from 'src/modules/auth/authSelectors';
import { Divider, Dropdown, Menu } from 'antd';
import { dropToken } from '../../../auth/authActions';

export const CurrentUser: React.FC = () => {
    const currentUserName = useSelector(getCurrentUserName);
    const version = process.env.REACT_APP_VERSION;
    const dispatch = useDispatch();

    const logoutCurrentUser = () => {
        dispatch(dropToken());
    };

    const getMenuItems = () => [
        <Menu.Item key={1} css={[styles.item, styles.disabledItem]} disabled>
            <div css={styles.bigText}>Über diese App</div>
            <div css={styles.smallText}>MOP version {version}</div>
        </Menu.Item>,
        <Divider key={2} css={styles.divider} />,
        <Menu.Item key={3} css={styles.item} onClick={logoutCurrentUser}>
            <div css={styles.bigText}>Abmelden</div>
        </Menu.Item>,
    ];

    return (
        <Dropdown
            overlay={<Menu css={styles.list}>{getMenuItems()}</Menu>}
            trigger={['click']}
            getPopupContainer={(trigger) => trigger ?? document.body}
        >
            <div css={styles.userWrapper} data-test={'userWrapper'}>
                <div css={styles.userIcon} data-test={'userIcon'}>
                    <Icon component={User} />
                </div>
                <div data-test={'userName'}>{currentUserName}</div>
            </div>
        </Dropdown>
    );
};

const styles = {
    userWrapper: css`
        margin-bottom: 104px;
        font-family: Gebauer-Regular, serif;
        font-size: ${fontSizes.mSmall};
        display: flex;
        align-items: center;
        cursor: pointer;
    `,
    smallText: css`
        font-size: ${fontSizes.xxSmall};
        color: ${colors.black};
    `,
    disabledItem: css`
        cursor: default !important;
    `,
    divider: css`
        margin: 0;
    `,
    bigText: css`
        font-size: ${fontSizes.mSmall};
        color: ${colors.black};
    `,
    item: css`
        padding: 16px 32px;
        width: 100%;
    `,
    list: css`
        position: absolute;
        margin-top: 8px;
        min-height: 50px;
        background-color: ${colors.primary};
        display: flex;
        width: 250px !important;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 5px;
        box-shadow: ${shadows.card};
    `,
    userIcon: css`
        width: 40px;
        height: 40px;
        margin-right: 16px;
        background-color: ${colors.fontHeader};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 24px;
            height: 24px;

            path {
                fill: ${colors.primary};
            }
        }
    `,
};
