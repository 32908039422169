/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { PlusCircle } from 'src/assets/icons';
import { colors, fontSizes } from 'src/constants/variables';

type Props = {
    text: string;
    onButtonClick?: () => void;
};

export const AddProductTile: React.FC<Props> = ({ text, onButtonClick }) => (
    <div css={styles.container} data-test={'addProductTile'}>
        <Icon
            component={PlusCircle} // Waiting for correct icon
            css={styles.icon}
            onClick={onButtonClick}
        />
        <div css={styles.text}>{text}</div>
    </div>
);

const styles = {
    container: css`
        height: 240px;
        background-color: ${colors.background}50;
        border-radius: 5px;
        padding: 74px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
    `,
    icon: css`
        cursor: pointer;

        svg {
            height: 48px;
            width: 48px;

            g {
                stroke: ${colors.newOfferIcon};
            }
        }
    `,
    text: css`
        margin-top: 24px;
        text-align: center;
        opacity: 0.5;
        color: ${colors.newOfferSecondary};
        font-size: ${fontSizes.mSmall};
        font-family: Gebauer-Regular, sans-serif;
    `,
};
