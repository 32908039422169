/** @jsxImportSource @emotion/react */
import React from 'react';

import { Services, ServicesProps } from '../../Offer/Services/Services';
import { HorizontalLineElement } from './HorizontalLineElement';
import { MBKServices } from '../../Offer/Services/MBKServices';
import { OfferFormProps } from '../../types';
import { FormInstance } from 'antd';

export type SecondColumnProps = Pick<ServicesProps, 'selectedServices'> & {
    offerServices: ServicesProps['services'];
    setSelectedServices: ServicesProps['setServices'];
    handleWeeklyTotalPriceAllItemsChange: (isSet?: boolean) => void;
    form: FormInstance<OfferFormProps>;
};

export const SecondColumn: React.FC<SecondColumnProps> = ({
    offerServices,
    selectedServices,
    setSelectedServices,
    form,
}) => (
    <>
        <Services
            services={offerServices}
            selectedServices={selectedServices}
            setServices={setSelectedServices}
        />

        <HorizontalLineElement />

        <MBKServices form={form} />
    </>
);