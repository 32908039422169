/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { colors, fontSizes } from 'src/constants/variables';
import { beautifyName } from '../../../../helpers/utils';

export type NavigationElementData = {
    link: string;
    name: string;
    subPage?: string;
};

type Props = NavigationElementData & {
    active: boolean;
};

export const NavigationElement: React.FC<Props> = ({ link, name, active }) => (
    <li css={styles.wrapper} data-test={`${beautifyName(name)}_BUTTON`}>
        <Link to={link} css={[styles.link, active && activeStyle]}>
            {name}
        </Link>
    </li>
);

const activeStyle = css`
    background-color: ${colors.fontHeader};
    color: ${colors.link};
`;

const styles = {
    wrapper: css`
        list-style-type: none;
    `,
    link: css`
        width: 100%;
        height: 100%;
        padding: 24px;
        display: inline-block;
        text-transform: uppercase;
        cursor: pointer;
        font-family: Gebauer-Medium;
        line-height: 1;
        font-size: ${fontSizes.mSmall};
        color: ${colors.fontSecondaryDark};
        border-radius: 5px;

        &:hover {
            ${activeStyle}
        }
    `,
};
