/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import { colors, fontSizes } from 'src/constants/variables';
import { AdditionalItem } from '../../../../../helpers/types';

type Props = {
    item: AdditionalItem;
    customCss?: SerializedStyles;
};

export const AdditionalHeaderColumn: React.FC<Props> = ({ item }) => (
    <div css={styles.wrapper}>
        <div css={styles.image}>
            <img src={item.image} alt={item.mainHeading} />
        </div>

        <div css={styles.details}>
            <p css={[styles.mainHeading, !item?.subHeading && css`padding-top: 2px;`]}>
                {item.mainHeading}
            </p>

            {
                item?.subHeading &&
                <p>
                    {item.subHeading}
                </p>
            }
        </div>
    </div>
);

const styles = {
    wrapper: css`
        display: flex;
        align-items: center;
        position: relative;
        padding-top: 20px;
        padding-left: 8px;
        padding-bottom: 16px;
    `,
    image: css`
        width: 64px;
        height: 64px;
        min-width: 64px;
        cursor: pointer;
        position: relative;
        margin-right: 19px;
        background-color: ${colors.fontPrimary};

        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        img {
            width: 56px;
            height: 56px;
        }
    `,
    details: css`
        font-family: 'Gebauer-Medium', sans-serif;
        font-size: ${fontSizes.xxSmall};
        line-height: 18px;
    `,
    mainHeading: css`
        font-weight: 700;
        font-size: ${fontSizes.mSmall};
        margin: 0;
        padding-top: 12px;
    `,
};
