/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react';
import moment from 'moment';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { Edit } from 'src/assets/icons';
import { offerImages } from 'src/assets/images/offer';
import { desktopScreenStyles, mobileScreenStyles, wideScreenStyles } from 'src/style/styles';
import { colors } from 'src/constants/variables';
import { ReadOnlyContext } from 'src/helpers/ReadOnlyContext';

import { BackgroundSelectionModal } from '../BackgroundSelectionModal';
import { CompanyNameElement } from '../CompanyNameElement';

type Props = {
    title: string;
    content: string;
    selectedDate: moment.Moment | null;
    setSelectedDate: (date: moment.Moment | null) => void;
    selectedBackground: string;
    setSelectedBackground: (uri: string) => void;
    allowDateClear?: boolean;
    readonly?: boolean;
    companyNameFooter?: React.ReactNode;
};

export const MainSection: React.FC<Props> = ({
    title,
    content,
    selectedDate,
    setSelectedDate,
    selectedBackground,
    setSelectedBackground,
    allowDateClear,
    readonly = false,
    companyNameFooter,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const readOnly = useContext(ReadOnlyContext) || readonly;

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    return (
        <div css={styles.container(selectedBackground)}>
            <BackgroundSelectionModal
                visible={isModalOpen}
                close={toggleModal}
                selected={selectedBackground}
                setSelected={setSelectedBackground}
            />
            {!readOnly && <Icon component={Edit} css={styles.button} onClick={toggleModal} data-test={'editBackgroundButton'}/>}

            <CompanyNameElement
                title={title}
                content={content}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                allowDateClear={allowDateClear}
                footer={companyNameFooter}
            />
        </div>
    );
};

const styles = {
    container: (background: string) => css`
        padding-top: 400px;
        padding-bottom: 80px;
        padding-left: 97px;
        background-color: gray;
        position: relative;
        font-family: 'Gebauer-Regular', sans-serif;
        background-image: url(${offerImages[background]});

        background-size: cover;
        ${mobileScreenStyles(css`
            min-height: 476px;
        `)};
    `,
    button: css`
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: ${colors.primary};
        position: absolute;
        right: 32px;
        top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        ${desktopScreenStyles(css`
            background-color: rgba(184, 33, 33, 0.5);
            height: 48px;
            width: 48px;

            &.anticon svg {
                height: 22px;
                width: 22px;

                path {
                    fill: none;
                    stroke: ${colors.primary};
                }

                polygon {
                    fill: ${colors.primary};
                    stroke: ${colors.primary};
                }
            }
        `)};

        ${wideScreenStyles(css`
            background-color: rgba(184, 33, 33, 0.5);
            height: 48px;
            width: 48px;

            &.anticon svg {
                height: 22px;
                width: 22px;

                path {
                    fill: none;
                    stroke: ${colors.primary};
                }

                polygon {
                    fill: ${colors.primary};
                    stroke: ${colors.primary};
                }
            }
        `)};
    `,
};
