/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { colors, fontSizes } from 'src/constants/variables';

interface Props {
    selectedWeight: number;
    weights: number[];
    onChange: (weight: number) => void;
}

export const WeightSelector: React.FC<Props> = ({ selectedWeight, weights, onChange }) => (
    <div css={styles.wrapper}>
        {weights.map((weight) => (
            <button
                key={weight}
                css={[styles.button, selectedWeight === weight && styles.selected]}
                onClick={() => onChange(weight)}
            >
                {weight}
            </button>
        ))}
    </div>
);

const styles = {
    wrapper: css`
        display: flex;
    `,
    button: css`
        flex-grow: 1;
        height: 67px;
        padding: 5px 0 0 0;

        background-color: ${colors.fontSecondaryDark};
        border: none;

        color: ${colors.primary};
        font-family: Gebauer-Regular;
        font-size: ${fontSizes.xLarge};

        :focus {
            outline: none;
        }

        cursor: pointer;
    `,
    selected: css`
        background-color: ${colors.secondary};
    `,
};
