/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { colors, fontSizes } from '../../../../constants/variables';

interface Props {
    selectedMaterial: string;
    materials: string[];
    onChange: (weight: string) => void;
}

export const MaterialSelector: React.FC<Props> = ({ selectedMaterial, materials, onChange }) => (
    <div css={styles.wrapper}>
        {materials.map((material) => (
            <button
                key={material}
                css={[styles.button, selectedMaterial === material && styles.selected]}
                onClick={() => onChange(material)}
            >
                {material}
            </button>
        ))}
    </div>
);

const styles = {
    wrapper: css`
        display: flex;
        padding-top: 20px;
    `,
    button: css`
        flex-grow: 1;
        flex-basis: 0;
        height: 67px;
        padding: 5px 0 0 0;

        background-color: ${colors.fontSecondaryDark};
        border: none;

        color: ${colors.primary};
        font-family: Gebauer-Regular;
        font-size: ${fontSizes.mSmall};

        :focus {
            outline: none;
        }

        cursor: pointer;
    `,
    selected: css`
        background-color: ${colors.secondary};
    `,
};
