import { OfferFormItem } from '../components/types';
import { VariantItem } from '../../../helpers/types';

export const prepareContactFullName = (contactData?: { name?: string; surname?: string }): string =>
    [contactData?.name, contactData?.surname].filter((element) => !!element).join(' ');

export const prepareCompanyFullAddress = (
    customerCompany: OfferBasics['customerCompany'],
): string =>
    [customerCompany?.street, customerCompany?.address].filter((element) => !!element).join(', ') ||
    '';

export const prepareCartItemData = (
    product: Product,
    variant: ProductVariant,
    formValues: OfferFormItem,
    totalPrice: number,
): ItemDetails => ({
    modelId: product.modelId,
    variantKey: variant.key,
    itemsGroupId: null,
    basePrice: formValues.basePrice || null,
    labelPrice: formValues.labelPrice || undefined,
    logoBackPrice: formValues.logoBackPrice || undefined,
    logoFrontPrice: formValues.logoFrontPrice || undefined,
    itemsPerWearer: formValues.itemsPerWearer || null,
    totalPrice: totalPrice || 0,
    psa: null,
});

export const prepareVariantItem = (
    product: Product,
    variant: ProductVariant,
    formValues: OfferFormItem,
    totalPrice: number,
): Omit<VariantItem, 'cartItemId' | 'offerItemId'> => ({
    modelId: product.modelId,
    modelName: product.modelName,
    category: product.category,
    variantKey: variant.key,
    variantName: variant.name,
    variantMaterial: variant.material,

    itemsPerWearer: formValues.itemsPerWearer || null,
    amountOfWearers: formValues.amountOfWearers,

    variantColor: product.colors.find((color) => color.key === variant.colorKey)!,
    variantWeight: variant.weight,
    imageLink: variant.imageLinks[0],

    norms: variant.norms,
    basePrice: formValues.basePrice || 0,
    labelPrice: formValues.labelPrice,
    logoBackPrice: formValues.logoBackPrice,
    logoFrontPrice: formValues.logoFrontPrice,
    totalPrice: totalPrice || 0,
    psa: false,
    discontinued: variant.discontinued,
    expired: variant.expired,
});
