/** @jsxImportSource @emotion/react */
import React from 'react';
import { Divider, Form } from 'antd';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { Edit } from 'src/assets/icons';
import { styles as formElementsStyles } from 'src/components/FormElements';
import { colors, fontSizes } from 'src/constants/variables';
import { TermsAndConditions } from '../../Offer/TermsAndConditions';
import { HorizontalLineElement } from './HorizontalLineElement';
import { TotalPricePerWeekElement } from './TotalPricePerWeekElement';
import { ServicesProps } from '../../Offer/Services/Services';

export type LastColumnProps = Pick<ServicesProps, 'selectedServices'> & {
    onKmuEdit: () => void;
    handleTotalPriceChange: (isSet?: boolean) => void;
    initialTermsAndConditions: boolean;
    setTermsAndConditions: (checkedTermsAndConditions: boolean) => void;
};

export const LastColumn: React.FC<LastColumnProps> = ({
    onKmuEdit,
    handleTotalPriceChange,
    selectedServices,
    initialTermsAndConditions,
    setTermsAndConditions,
}) => (
    <>
        <HorizontalLineElement />

        <div css={styles.notes} data-test={'kmuIntroductoryLetterWrapper"'}>
            <span css={styles.required}>KMU ANSCHREIBEN</span>
            <Icon component={Edit} css={styles.editIcon} onClick={onKmuEdit} data-test={'kmuIntroductoryLetterEditButton"'} />
        </div>
        <Divider css={styles.divider} />
        <div css={[styles.notes, styles.disabled]} data-test={'kamIntroductoryLetterWrapper"'}>
            <span>KAM ANSCHREIBEN</span>
            <Icon component={Edit} css={styles.editIcon} data-test={'kamIntroductoryLetterEditButton"'} />
        </div>
        <Divider css={styles.divider} />

        <TermsAndConditions
            initialTermsAndConditions={initialTermsAndConditions}
            setTermsAndConditions={setTermsAndConditions}
        />

        <Form.Item shouldUpdate css={styles.checkboxWrapper}>
            {({ getFieldValue }) => (
                <TotalPricePerWeekElement
                    checked={getFieldValue('totalPricePerWeek')}
                    handleTotalPriceChange={handleTotalPriceChange}
                    selectedServices={selectedServices}
                />
            )}
        </Form.Item>
    </>
);

const styles = {
    divider: css`
        margin: 0;
    `,
    notes: css`
        display: flex;
        justify-content: space-between;

        padding: 16px 24px 16px;

        font-size: ${fontSizes.mSmall};
        font-family: Gebauer-Regular, sans-serif;
    `,
    editIcon: css`
        align-self: center;

        svg {
            height: 24px;
            width: 24px;

            path {
                stroke: ${colors.secondary};
            }

            polygon {
                fill: ${colors.secondary};
                stroke: ${colors.secondary};
            }
        }
    `,
    disabled: css`
        opacity: 0.25;

        svg {
            path {
                stroke: ${colors.fontHeader};
            }

            polygon {
                fill: ${colors.fontHeader};
                stroke: ${colors.fontHeader};
            }
        }
    `,
    servicesContainer: css`
        background-color: ${colors.background};
    `,
    required: css`
        ${formElementsStyles.required};

        :after {
            font-size: inherit;
        }

        padding-right: 24px;
    `,
    checkboxWrapper: css`
        margin: 8px 0 0;
    `,
    checkbox: css`
        && .ant-checkbox {
            width: 24px;
            height: 24px;
            border-radius: 5px;
            margin-top: 2px;
            margin-right: 13px;
            border-color: ${colors.iconTime};
        }

        && .ant-checkbox + span {
            padding-left: 0;
            padding-right: 8px;
            font-size: ${fontSizes.mSmall};
            color: ${colors.newOfferCheckbox};
            font-family: Gebauer-Regular, sans-serif;
            user-select: none;
        }

        && .ant-checkbox-inner {
            width: 24px;
            height: 24px;
            border-radius: 5px;
            background-color: unset;
            color: ${colors.newOfferCheckbox};
        }
    `,
};
