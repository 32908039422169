/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Dropdown, Button } from 'antd';
import { css } from '@emotion/react';

import { CustomExpandIcon } from 'src/components/CustomExpandIcon';
import { horizontalLineBefore, horizontalLine, mobileScreenStyles } from 'src/style/styles';
import { fontSizes } from 'src/constants/variables';

import { offerDetailsStyles } from '../styles';
import { ServicesDropdownMenu } from './ServicesDropdownMenu';

type Props = {
    services: WardrobeOption[];
    selectedServices: number[];
    setSelectedServices: (ids: number[]) => void;
    label?: string;
    horizontalLineAfter?: boolean;
};

export const ServicesDropdown: React.FC<Props> = ({
    services,
    selectedServices,
    setSelectedServices,
    label = 'SERVICE AUSWÄHLEN',
    horizontalLineAfter = true,
}) => {
    const [isMenuVisible, setMenuVisible] = useState(false);

    return (
        <div css={styles.container(horizontalLineAfter)} data-test={'servicesDropdownWrapper'}>
            <Dropdown
                trigger={['click']}
                placement="bottomCenter"
                visible={isMenuVisible}
                onVisibleChange={setMenuVisible}
                overlay={
                    <ServicesDropdownMenu
                        services={services}
                        selectedServices={selectedServices}
                        setSelectedServices={setSelectedServices}
                        visible={isMenuVisible}
                        onClose={() => setMenuVisible(false)}
                    />
                }
                getPopupContainer={(trigger) => trigger.parentElement ?? document.body}
                forceRender
            >
                <Button type={'text'} css={offerDetailsStyles.dropdownButton} data-test={'servicesDropdownButton'}>
                    <span>{label}</span>
                    <CustomExpandIcon
                        isActive={isMenuVisible}
                        css={offerDetailsStyles.dropdownArrowIcon}
                    />
                </Button>
            </Dropdown>
        </div>
    );
};

const styles = {
    container: (horizontalLineAfter: boolean) => css`
        position: relative;

        ${horizontalLineAfter &&
        horizontalLine(css`
            margin: 0;
        `)};
        ${horizontalLineBefore(css`
            margin: 0;
        `)};

        .ant-dropdown {
            max-width: 100%;
            padding: 8px 8px 16px;

            ${mobileScreenStyles(css`
                padding: 8px 0 16px;
            `)};
        }

        & * {
            font-size: ${fontSizes.mSmall};
            font-family: Gebauer-Regular, sans-serif;
            text-transform: uppercase;
        }
    `,
};
