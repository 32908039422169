import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { colors } from 'src/constants/variables';

import { ProductListItem } from 'src/modules/offerPdf/templates/pdfPages/productListPage/productListItem';
import { VariantItem } from '../../../../../helpers/types';

const styles = StyleSheet.create({
    productList: {
        position: 'relative',
        minWidth: '100%',
        width: '100%',
        marginTop: '20px',
    },
    productListHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        fontSize: '6.5px',
        borderBottom: `0.5px solid ${colors.secondaryDark}`,
        fontFamily: 'GebauerMedium',
    },
    productListTableCol34: {
        flexBasis: '24.5%',
        maxWidth: '24.5%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    productListNormalTableCol: {
        flexBasis: 'calc(10.5% - 4px)',
        maxWidth: 'calc(10.5% - 4px)',
        padding: '2px',
        textAlign: 'right',
        paddingBottom: '8px',
        paddingTop: '4px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    productListTableCol1: {
        flexBasis: 'calc(5% - 4px)',
        maxWidth: 'calc(5% - 4px)',
        padding: '2px',
        textAlign: 'left',
        paddingBottom: '8px',
        paddingTop: '4px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    subheading: {
        fontSize: '9.5px',
        fontFamily: 'GebauerBold',
        color: colors.darkRed,
    },
});

type Props = {
    itemList: VariantItem[];
    displayCategory: boolean;
};

export const ProductList: React.FC<Props> = ({ itemList, displayCategory}) => (
    <View style={styles.productList}>
        <Text style={styles.subheading}>Outfit</Text>

        <View style={styles.productListHeader} fixed>
            <View style={styles.productListTableCol1}>
                <Text>Pos.</Text>
            </View>
            <View style={{ ...styles.productListNormalTableCol, textAlign: 'left' }}>
                <Text>Artikel</Text>
            </View>
            <View style={styles.productListTableCol34}></View>
            <View style={styles.productListNormalTableCol}>
                <Text>Einzukl.</Text>
                <Text>Personen/</Text>
                <Text>Menge</Text>
            </View>
            <View style={styles.productListNormalTableCol}>
                <Text>Ausstattung</Text>
            </View>
            <View style={styles.productListNormalTableCol}>
                <Text>Preis pro</Text>
                <Text>Woche</Text>
                <Text>je Artikel</Text>
            </View>
            <View style={styles.productListNormalTableCol}>
                <Text>Brustlogo</Text>
            </View>
            <View style={styles.productListNormalTableCol}>
                <Text>Rückenlogo</Text>
            </View>
            <View style={styles.productListNormalTableCol}>
                <Text>Namensschild</Text>
            </View>
            <View style={styles.productListNormalTableCol}>
                <Text>Preis pro</Text>
                <Text>Woche je</Text>
                <Text>einzukl. Person</Text>
            </View>
        </View>

        <View>
            {
                itemList &&
                itemList.map((item: VariantItem, i: number) =>
                    <ProductListItem key={i} item={item} displayCategory={displayCategory} pos={i + 1} />
                )
            }
        </View>
    </View>
);
