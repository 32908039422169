import React, { CSSProperties, useState } from 'react';

import { Tooltip } from 'antd';
import { colors, fontSizes } from 'src/constants/variables';

export interface ColorTooltipProps {
    text: string;
}

export const WarningTooltip: React.FC<ColorTooltipProps> = ({ text, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            <Tooltip
                placement="top"
                title={text}
                arrowPointAtCenter
                overlayInnerStyle={tooltipStyles}
                color={colors.secondaryBright}
                visible={showTooltip}
            >
                <div>{children}</div>
            </Tooltip>
        </div>
    );
};

const tooltipStyles = {
    color: colors.fontHeaderSecondary,
    fontFamily: 'OpenSans-Bold, sans-serif',
    textTransform: 'uppercase',
    fontSize: fontSizes.xxSmall,
    textAlign: 'center',
} as CSSProperties;
