/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { colors } from '../../../../constants/variables';

export const LegalInformation = () => (
    <div css={styles.legalInformation}>
        <p css={styles.legalText}>
            Die Preise basieren auf den hier angegebenen Mengen und gelten zzgl. MwSt.
        </p>

        <p css={styles.legalText}>
            Individualisierte Ware ist von Umtausch/Rückgabe ausgeschlossen
        </p>
    </div>
);

const styles = {
    legalInformation: css`
        color: ${colors.primaryDark};
        background-color: ${colors.primary};
        padding-left: 40px;
        margin-top: 14px;
    `,
    legalText: css`
        margin: 0;
        padding-top: 2px;
    `
}