import { css, SerializedStyles } from '@emotion/react';

import {
    colors,
    fontSizes,
    breakpoints,
    wideScreenBreakpoint,
    desktopScreenBreakpoint,
    sizes,
    shadows,
} from '../constants/variables';

export const acceptButton = css`
    display: block;
    width: 100%;
    line-height: 56px;
    padding: 0;

    text-align: center;
    font-family: Gebauer-Regular, sans-serif;
    font-size: ${fontSizes.medium};
    background-color: ${colors.secondary};
    color: ${colors.fontPrimary};
    text-transform: uppercase;
`;

export const cancelButton = css`
    display: block;
    width: 100%;
    line-height: 56px;
    padding: 0;

    text-align: center;
    font-family: Gebauer-Regular, sans-serif;
    font-size: ${fontSizes.medium};
    background-color: ${colors.primary};
    color: ${colors.fontSecondary};
    text-transform: uppercase;
`;

type DarkenImageOptions = { styles?: SerializedStyles; opacity?: number } | undefined;

export const darkenImage = (imageUrl: string, options?: DarkenImageOptions) => {
    const styles = options && options.styles;
    const opacity = options && options.opacity;

    return css`
        position: relative;
        background-color: rgba(0, 0, 0, ${opacity || 0.3});

        ::after {
            content: '';
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;

            background-image: url(${imageUrl});
            background-position: center;
            background-size: cover;
        }

        ${styles}
    `;
};

export const horizontalMark = (styles = css``) => css`
    position: relative;

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 3px;
        background-color: ${colors.secondary};

        ${styles}
    }
`;

const horizontalLineCss = css`
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    margin: 0 16px;
    background-color: ${colors.fontSecondaryBright};
`;

export const horizontalLine = (styles = css``) => css`
    position: relative;

    :after {
        ${horizontalLineCss};
        bottom: 0;
        ${styles}
    }
`;

export const horizontalLineBefore = (styles = css``) => css`
    position: relative;

    :before {
        ${horizontalLineCss};
        top: 0;
        ${styles}
    }
`;

export const elementCustomScrollbar = (color = colors.secondaryDark, width = 3) => css`
    scrollbar-color: ${color};

    ::-webkit-scrollbar {
        width: ${width}px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${color};
    }
`;

export const textOverflowEllipsis = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const mobileScreenStyles = (styles = css``) => css`
    @media (max-width: calc(${wideScreenBreakpoint} - 1px)) {
        ${styles};
    }
`;

export const wideScreenStyles = (styles = css``) => css`
    @media (min-width: ${wideScreenBreakpoint}) {
        ${styles};
    }
`;

export const desktopScreenStyles = (styles = css``) => css`
    @media (min-width: ${desktopScreenBreakpoint}) {
        ${styles};
    }
`;

export const listStyle = css`
    margin-bottom: 24px;
    padding-left: 18px;
    font-family: 'Open Sans', sans-serif;
    font-size: ${fontSizes.small};
`;

export const warningText = css`
    margin-bottom: 24px;
    padding-left: 0px;
    font-family: 'Open Sans', sans-serif;
    color: ${colors.secondary};
    font-style: italic;
`;

export const listBasicStyles = css`
    ${listStyle};
    padding: 0;
    list-style-type: none;
`;

export const floatCartButton = (backgroundColor: string) => css`
    position: fixed;
    border: none;
    bottom: calc(32px + ${sizes.bottomBar});
    right: 32px;
    width: 64px;
    height: 64px;

    line-height: 1;
    box-shadow: ${shadows.ctaButton};

    &.ant-btn,
    &.ant-btn:focus,
    &.ant-btn:hover {
        background: ${backgroundColor};
    }

    &.ant-btn[disabled] {
        opacity: 50%;
    }

    ${desktopScreenStyles(css`
        bottom: 32px;
    `)};

    @media (max-width: calc(${breakpoints.medium} - 1px)) and (orientation: landscape) {
        bottom: 32px;
    }
`;

export const noticeTextArea = css`
    padding: 8px;
    margin-bottom: 24px;

    background-color: ${colors.secondaryBright};
    font-family: 'Open Sans', sans-serif;
    color: ${colors.fontPrimaryDark};

    && {
        border: none;
        box-shadow: none;
    }

    ${elementCustomScrollbar()};

    @media (min-width: ${breakpoints.xLarge}) {
        && {
            height: 150px;
            resize: none;
        }
    }
`;

export const datePickerStyles = {
    wrapper: css`
        .ant-picker-footer {
            display: none;
        }

        .ant-picker-cell-inner {
            border-radius: 5px;
            background: none !important;
        }

        .ant-picker-cell-inner {
            &:hover {
                border: 1px solid ${colors.secondary};
                background: transparent !important;
                box-sizing: border-box;
            }
        }

        .ant-picker-cell-today .ant-picker-cell-inner {
            &::before {
                border: none;
            }
        }

        .ant-picker-cell-selected .ant-picker-cell-inner {
            background: rgba(184, 33, 33, 0.25) !important;
            border: 1px solid #b82121;
            color: #000;
        }

        button.ant-picker-year-btn,
        button.ant-picker-month-btn {
            pointer-events: none;
        }
    `,
    dateInput: css`
        &&.ant-picker .ant-picker-input {
            cursor: pointer;
        }

        &&.ant-picker-disabled .ant-picker-input {
            cursor: not-allowed;
        }

        .ant-picker-clear svg {
            width: 26px;
            height: 20px;
        }

        .ant-picker-suffix svg {
            width: 26px;
            height: 22px;
        }
    `,
};

export const dropdownChevronStyles = css`
    &.ant-dropdown-trigger > .anticon.anticon-down {
        transition-duration: 100ms;
    }

    &.ant-dropdown-trigger.ant-dropdown-open > .anticon.anticon-down {
        transform: rotate(-180deg);
        transition-duration: 150ms;
    }
`;
