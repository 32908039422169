/** @jsxImportSource @emotion/react */
import React from 'react';
import { Form, Row, Col } from 'antd';
import { css } from '@emotion/react';

import { Input, styles as formElementsStyles } from 'src/components/FormElements';
import { colors } from 'src/constants/variables';

import { isOfferKmuFormValid } from 'src/modules/offer/helpers/offerForm';
import { ConfirmationButton } from '../MewaCustomButton';

export type KmuFormValues = {
    intro: string;
    outro: string;
    hints: string;
};

type Props = {
    onSubmit: (values: KmuFormValues) => void;
    initialValues?: Partial<KmuFormValues>;
};

export const KmuDetailsForm: React.FC<Props> = ({ initialValues = {}, onSubmit }) => (
    <Form initialValues={initialValues} onFinish={onSubmit} preserve={false}>
        <Row gutter={32}>
            <Col span={24}>
                <div css={[styles.headline]}>
                    <span css={styles.required}>Intro</span>
                </div>
                <Input.TextArea
                    name={'intro'}
                    rows={15}
                    placeholder="Fügen Sie hier Ihren Text ein."
                    formItemCss={styles.textarea}
                />
            </Col>
        </Row>
        <Row gutter={32}>
            <Col span={24}>
                <div css={styles.headline}>
                    <span css={styles.required}>Outro</span>
                </div>
                <Input.TextArea
                    name={'outro'}
                    rows={17}
                    placeholder="Fügen Sie hier Ihren Text ein."
                    formItemCss={styles.textarea}
                />
            </Col>
        </Row>
        <Row gutter={32}>
            <Col span={24}>
                <div css={styles.headline}>Hinweise</div>
                <Input.TextArea
                    name={'hints'}
                    rows={9}
                    maxLength={800}
                    placeholder="Fügen Sie hier Ihren Text ein."
                    formItemCss={styles.textarea}
                />
            </Col>
        </Row>
        <Row justify={'end'}>
            <Col>
                <Form.Item css={styles.formSubmit} shouldUpdate>
                    {({ getFieldsValue }) => (
                        <ConfirmationButton disabled={!isOfferKmuFormValid(getFieldsValue())}>
                            EINFÜGEN
                        </ConfirmationButton>
                    )}
                </Form.Item>
            </Col>
        </Row>
    </Form>
);

const styles = {
    headline: css`
        font-size: 16px;
        font-family: Gebauer-Medium, sans-serif;
        color: ${colors.fontSecondaryDark};
        text-transform: uppercase;
        margin: 16px 0 32px;
    `,
    textarea: css`
        textarea.ant-input {
            &,
            &:focus,
            &:hover {
                line-height: 21px;
                border-radius: 5px;
                background-color: ${colors.backgroundModal};
                color: ${colors.fontPrimaryDark};
            }

            ::placeholder {
                opacity: 0.5;
                color: ${colors.fontPrimaryDark};
            }
        }
    `,
    required: css`
        ${formElementsStyles.required};
        padding-right: 24px;
    `,
    formSubmit: css`
        button {
            width: 400px;
            border-radius: 5px;
        }
    `,
};
