import React from 'react';

import { FormItemDetails } from './FormItemDetails';
import { ModalItemDetails } from './ModalItemDetails';
import { VariantItem } from '../../../../../helpers/types';
import { ItemExpiredDetails } from './ItemExpiredDetails';

type Props = {
    item: VariantItem;
    form?: boolean;
};

export const ItemDetails: React.FC<Props> = ({ item, form }) =>
    !item.expired ? (form  ? <FormItemDetails item={item} /> : <ModalItemDetails item={item} />) :
        <ItemExpiredDetails item={item} />;
