import React from 'react';
import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { DeliveryOption } from './delivery/deliveryOption';
import { colors } from 'src/constants/variables';
import { DeliveryData } from '../../types';
import { PageNumber } from '../../components/pageNumber';
import { pageStyles } from '../../components/pageTitle';

import MewaLogoRed from '../../../../assets/images/logo/mewa-logo_red.png';

const styles = StyleSheet.create({
    section: {
        position: 'relative',
        minHeight: '100%',
        height: '100%',
    },
    optionsContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '54px',
    },
    logoPic: {
        position: 'absolute',
        width: '214.67px',
        height: '40px',
        top: '40px',
        left: '40px',
    },
    pageNumberAndTotal: {
        position: 'absolute',
        left: '518px',
        fontFamily: 'GebauerRegular',
        fontSize: '6.5px',
    },
    text: {
        fontSize: '11px',
        fontFamily: 'GebauerRegular',
    },
    redBold: {
        fontFamily: 'GebauerBold',
        color: colors.darkRed,
    },
    bold: {
        fontFamily: 'GebauerBold',
    },
    hintsContainer: {
        position: 'relative',
        paddingTop: '30px',
        paddingRight: '30px',
        left: '10px',
    },
});

type Props = {
    delivery: DeliveryData;
    hints: string | null | undefined;
    pageOffset: number;
};

function getPeriods(periods: number[]) {
    const periodsText = [];

    for (let i = 0; i < periods.length; ++i) {
        periodsText.push(
            <span key={2 * i} style={{ fontFamily: 'GebauerBold' }}>{periods[i]}-facher</span>
        )

        if (i < periods.length - 2) {
            periodsText.push(
                <span key={2 * i + 1}>, </span>
            )
        } else if (i === periods.length - 2) {
            periodsText.push(
                <span key={2 * i + 1}> und </span>
            )
        }
    }

    return periodsText;
}

export const DeliverySchemePage: React.FC<Props> = ({ delivery, hints, pageOffset}) => (
    <Page size="A4">
        <View style={styles.section} >
            <Image style={styles.logoPic} src={MewaLogoRed} />

            <Text
                style={{
                    ...styles.pageNumberAndTotal,
                    top: '30px'
                }}

                render={({ pageNumber, totalPages }) => (
                    `Seite ${pageNumber} von ${totalPages - pageOffset + 1}`
                )}

                fixed
            />

            <View style={{ ...pageStyles.page, paddingTop: '120px' }}>
                <Text style={{ ...styles.redBold, fontSize: '16px' }}>
                    Berufskleidung im Rundum-Service
                </Text>

                <Text style={{ paddingTop: '3px' }}>{delivery.length}-tägiger Lieferrhythmus</Text>

                <Text style={{ paddingTop: '3px' }}>
                    Beispielrechnung bei {getPeriods(delivery.periods)} Ausstattungsmenge*
                </Text>

                <View style={{ width: '319px', paddingTop: '3px' }}>
                    <Text style={{ fontSize: '8px' }}>
                        *Die notwendige Ausstattungsmenge an Kleidungsteilen variiert abhängig vom gewählten
                        Lieferrhythmus sowie der Häufigkeit mit der Sie Ihre Kleidung pro Woche wechseln werden.
                    </Text>
                </View>

                <div style={styles.optionsContainer}>
                    <DeliveryOption cycleLength={delivery.length} period={delivery.periods[0]} data={delivery.option1} addBorderBottom />
                    <DeliveryOption cycleLength={delivery.length} period={delivery.periods[1]} data={delivery.option2} addBorderBottom />
                    <DeliveryOption cycleLength={delivery.length} period={delivery.periods[2]} data={delivery.option3} />
                </div>

                {!!hints && (
                    <div style={styles.hintsContainer}>
                        <Text style={{ ...styles.redBold, fontSize: '12px' }}>Hinweise:</Text>
                        <Text style={{ fontSize: '10px', paddingTop: '2px' }}>
                            {hints.split('')}
                        </Text>
                    </div>
                )}
            </View>

            <Text
                style={{
                    ...styles.pageNumberAndTotal,
                    bottom: '38px',
                }}

                render={({ pageNumber, totalPages }) => (
                    `Seite ${pageNumber} von ${totalPages - pageOffset + 1}`
                )}

                fixed
            />

            <PageNumber offset={pageOffset} />
        </View>
    </Page>
);
