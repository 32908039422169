import { css } from '@emotion/react';

import { simpleInputCss } from 'src/components/DataInputs';
import { desktopScreenStyles, horizontalMark, mobileScreenStyles } from 'src/style/styles';
import { colors, fontSizes, shadows } from 'src/constants/variables';

export const offerDetailsStyles = {
    sectionWrapper: css`
        padding-bottom: 32px;
    `,
    sectionWrapperBoxed: css`
        padding: 0 32px 32px;

        ${mobileScreenStyles(css`
            padding: 16px 0 0;
        `)};
    `,

    dropdownWrapper: css`
        padding: 16px;
        border-radius: 5px;
        background: ${colors.primary};
        box-shadow: ${shadows.selected};
    `,
    dropdownButton: css`
        width: 100%;
        height: auto;
        padding: 14px 16px;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        white-space: break-spaces;
        &,
        &:hover,
        &:focus {
            background: transparent;
        }
    `,
    dropdownArrowIcon: css`
        float: right;
        svg {
            color: ${colors.secondary};
        }
    `,

    dropdownMenu: css`
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 156px;
        border-width: 0;
        background: transparent;
    `,
    dropdownMenuItem: css`
        &&.ant-menu-item {
            height: 55px;
            line-height: 22px;

            padding: 16px;
            margin: 0 16px 0 0;

            &:not(:last-of-type) {
                border-bottom: 1px solid ${colors.secondaryDark};
            }
        }

        &&.ant-menu-item,
        &&.ant-menu-item:focus,
        &&.ant-menu-item:hover {
            color: ${colors.fontSecondaryDark};
        }

        &&.ant-menu-item-selected,
        &&.ant-menu-item-selected:focus,
        &&.ant-menu-item-selected:hover {
            color: ${colors.secondary};
            background-color: transparent;
        }
    `,
    dropdownNoItems: css`
        font-style: italic;
    `,

    dataField: css`
        & > div {
            width: 97px;
        }

        & > span {
            width: auto;
            max-width: calc(100% - 97px);
            margin-bottom: 24px;
            padding-right: 16px;
        }
    `,
    dataInput: css`
        ${simpleInputCss};
    `,
};

export const offerUserCustomerOverviewStyles = {
    titleInput: css`
        width: 167px;
        .ant-select-arrow {
            right: 24px;
        }
    `,
    formTitle: css`
        text-transform: uppercase;
        font-size: ${fontSizes.mSmall};
        font-family: Gebauer-Medium, sans-serif;
        font-weight: 700;
        margin-bottom: 24px;
        margin-top: 57px;
        line-height: 1;
    `,
    date: css`
        color: ${colors.fontAppointment};
        margin-top: 40px;
        text-transform: uppercase;
        font-size: ${fontSizes.xMedium};
        font-family: Gebauer-Regular, sans-serif;
        margin-bottom: 44px;
    `,
    title: css`
        color: ${colors.primaryDark};
        font-family: Gebauer-Regular, sans-serif;
        font-size: ${fontSizes.xMedium};
        line-height: 1;
        text-transform: uppercase;
        padding-top: 18px;
        margin-bottom: 0;
        ${horizontalMark()};
    `,
};

export const redCustomCheckboxStyle = {
    checkbox: css`
        position: relative;

        && .ant-checkbox {
            width: 24px;
            height: 24px;
            min-width: 24px;
            border-radius: 3px;
            margin-right: 13px;
            border: 1px solid ${colors.iconTime};

            display: flex;
            justify-content: center;
            align-items: center;

            & + span {
                padding-left: 0;
                padding-right: 8px;
                font-size: ${fontSizes.mSmall};
                color: ${colors.newOfferCheckbox};
                font-family: Gebauer-Regular;
                user-select: none;

                ${desktopScreenStyles(css`
                    max-width: 290px;
                `)}
            }
        }

        && .ant-checkbox-checked {
            border: 1px solid ${colors.secondary};
        }

        &&.ant-checkbox-wrapper {
            display: flex;
            align-items: center;
            font-size: ${fontSizes.xxSmall};
            line-height: 20px;
        }

        && .ant-checkbox-inner {
            width: 18px;
            height: 18px;
            border: none;
            border-radius: 3px;
            background-color: transparent;

            &::after {
                display: none;
            }
        }

        && .ant-checkbox-checked::after {
            display: none;
        }

        && .ant-checkbox-checked .ant-checkbox-inner {
            background-color: ${colors.secondary};

            &::after {
                display: none;
            }
        }
    `,
    priceCheckbox: css`
        display: flex;

        .ant-checkbox-wrapper {
            display: flex;
            align-items: center;
        }
    `,
    price: css`
        align-self: center;
        min-width: 40px;
        font-size: ${fontSizes.mSmall};
        font-family: Gebauer-Regular;
        color: ${colors.newOfferCheckbox};
        text-align: center;

        position: absolute;

        :after {
            content: '€';
            margin-left: 10px;
            top: 50%;
            font-family: Gebauer-Regular, sans-serif;
        }
    `,
};
