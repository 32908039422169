import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { parseToPrice } from 'src/helpers/utils';

const styles = StyleSheet.create({
    summaryContainer: {
        padding: '10px 40px',
        backgroundColor: '#F9F9F9',
        width: '100vw',
        marginTop: '52px',
        left: '-40px',
    },
    namePriceContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        fontSize: '8px',
        lineHeight: '1.5',
        textAlign: 'right',
    },
    nameLabel: {
        width: '88%',
        fontFamily: 'GebauerRegular',
        fontSize: '9px',
    },
    bigNameLabel: {
        fontFamily: 'GebauerRegular',
        fontSize: '15px',
    },
    priceLabel: {
        width: '12%',
        fontFamily: 'GebauerMedium',
        fontSize: '10px',
    },
    bigPriceLabel: {
        fontFamily: 'GebauerMedium',
        fontSize: '15px',
    },
});

type Props = {
    details: OfferDetails | undefined;
};

const COMMA = ',';

export const ProductListPageSummary: React.FC<Props> = ({ details }) => (
    <View style={styles.summaryContainer} wrap={false}>
        <View style={styles.namePriceContainer}>
            <Text style={styles.nameLabel}>Lieferpauschale pro Woche je Lieferstelle</Text>
            <Text style={styles.priceLabel}>
                {details?.deliveryPricePerWeek
                    ? parseToPrice(details?.deliveryPricePerWeek, COMMA)
                    : '-'}
                {' '}€
            </Text>
        </View>

        {
            details?.assemblyFee &&
            <View style={{ ...styles.namePriceContainer, paddingTop: '4px'}}>
                <Text style={{ ...styles.nameLabel, fontFamily: 'GebauerMedium' }}>Konfektionsgebühr</Text>
                <Text style={styles.priceLabel}>
                    {parseToPrice(details.assemblyPrice!, COMMA)}
                    {' '}€
                </Text>
            </View>
        }

        {
            details?.totalPricePerWeek &&
            <View style={{ ...styles.namePriceContainer, paddingTop: '12px', flexDirection: 'column' }}>
                <Text style={styles.bigNameLabel}>Wöchentlicher Gesamtpreis</Text>
                <Text style={styles.bigPriceLabel}>
                    {details?.totalPricePerWeekValue
                        ? parseToPrice(details?.totalPricePerWeekValue, COMMA)
                        : '-'}
                    {' '}€
                </Text>
            </View>
        }
    </View>
);
