import { AdditionalServiceItem } from './types';

export const additionalServiceData: AdditionalServiceItem[] = [
    {
        label: "Konfektionsrollständer (Preis je Artikel pro Woche)",
        name: "clothingStand",
        inputs: [
            {
                label: "Menge",
                name: "clothingAmountOfWearers",
                isPrice: false,
                defaultValue: 1,
            },
            {
                label: "Preis",
                name: "clothingPrice",
                isPrice: true,
                defaultValue: 0.56,
            },
        ],
    },
    {
        label: "Kleiderhüllen (Preis je Artikel pro Woche)",
        name: "garmentCover",
        inputs: [
            {
                label: "Menge",
                name: "garmentAmountOfWearers",
                isPrice: false,
                defaultValue: 2,
            },
            {
                label: "Preis",
                name: "garmentPrice",
                isPrice: true,
                defaultValue: 0.25,
            },
        ],
    },
    {
        label: "Konfektionsgebühr",
        name: "assemblyFee",
        inputs: [
            {
                label: "Konfektionsgebühr",
                name: "assemblyPrice",
                isPrice: true,
                defaultValue: 2.80,
                min: 1.80,
            },
        ],
    },
];