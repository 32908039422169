/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { colors, fontSizes } from 'src/constants/variables';
import { Col, Row } from 'antd';
import { ColumnTitle } from '../../Offer/ProductRow/ColumnTitle';
import { AdditionalItemsList } from './AdditionalItemsList';
import { AdditionalItem } from '../../../../../helpers/types';

type Props = {
    items: AdditionalItem[];
    startingPos: number;
};

export const AdditionalItemsArea: React.FC<Props> = ({ items, startingPos }) => (
    <div>
        <h3 css={styles.subheading}>Zusatzartikel</h3>
        <Row>
            <Col span={1}>
                <ColumnTitle title={["Pos."]} first />
            </Col>
            <Col span={17} css={styles.articleHeader}>
                <ColumnTitle title={["Artikel"]} />
            </Col>
            <Col span={2}>
                <ColumnTitle title={["Menge"]} />
            </Col>
            <Col span={2}>
                <ColumnTitle title={["Preis pro", "Woche", "je Artikel"]} />
            </Col>
            <Col span={2}>
                <ColumnTitle title={["Preis pro", "Woche je", "einzukl. Person"]} />
            </Col>
        </Row>

        <div css={styles.itemsList}>
            <AdditionalItemsList items={items} startingPos={startingPos} />
        </div>
    </div>
);

const styles = {
    articleHeader: css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 22px;
    `,
    itemsList: css`
        border-top: 1px solid ${colors.backgroundDark}40;
        border-bottom: 1px solid ${colors.backgroundDark}40;
        margin-bottom: 16px;
    `,
    customizedItemsNote: css`
        font-size: ${fontSizes.mSmall};
        font-family: 'Gebauer-Regular', sans-serif;
    `,
    subheading: css`
        color: ${colors.fontHeader};
        font-family: Gebauer-Regular;
        font-size: 22px;
        padding-top: 48px;
    `,
};
