/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';
import { css } from '@emotion/react';

import { ColorMark } from 'src/components';
import { RemoveItemButton } from 'src/components/Buttons';
import { colors } from 'src/constants/variables';
import ImagePlaceHolderSVG from 'src/assets/icons/no_image_placeholder.svg';
import ExpiredArticleImagePlaceholderSVG from 'src/assets/icons/expired_article_image_placeholder.svg';

import { ItemDetails } from './ItemDetails';
import { VariantItem } from '../../../../../helpers/types';
import { beautifyName } from '../../../../../helpers/utils';
import { WarningTooltipWrapper } from '../../../../../components/WarningTooltipWrapper';

type Props = {
    onOfferItemClick: () => void;
    item: VariantItem;
    onRemove?: () => void;
    form?: boolean;
    warnings: boolean[];
};

export const HeaderColumn: React.FC<Props> = ({
    item,
    warnings,
    onRemove,
    onOfferItemClick,
    form,
}) => {
    const ref = useRef(null);

    function getConfirmationText() {
        if (item.expired) {
            return 'Sind Sie sicher, dass Sie einen nicht mehr verfügbaren Artikel löschen möchten? Die nicht verfügbaren Artikel können nicht noch einmal hinzugefügt werden.';
        } else if (item.discontinued) {
            return 'Sind Sie sicher, dass Sie den Auslaufartikel löschen wollen? Auslaufartikel können nicht wieder hinzugefügt werden.';
        }  else {
            return 'Möchten Sie diesen Artikel aus dem Angebot entfernen?';
        }
    }

    function getDiscontinuedTooltipText() {
        return 'Dieser Artikel ist ein Auslaufartikel und darf nicht mehr verkauft werden.';
    }

    function getExpiredTooltipText() {
        return 'Dieser Artikel ist nicht mehr verfügbar.';
    }

    return (
        <div
            css={styles.wrapper}
            ref={ref}
            data-test={`${beautifyName(item.modelName)}_DETAILS_SECTION`}
        >
            {onRemove && (
                <RemoveItemButton
                    confirm={getConfirmationText()}
                    onConfirm={onRemove}
                    promptDirectionRight={true}
                    customWrapperCss={styles.removeItemWrapper}
                    customIconCss={styles.removeItem}
                />
            )}

            <div
                css={styles.image}
                onClick={onOfferItemClick}
                role="button"
                data-test={'productImage'}
            >
                <div css={styles.warnings}>
                    {warnings.map((warn, i) => warn && <div key={i} css={styles.warning} />)}
                </div>
                <img
                    src={item.imageLink || (item.expired ? ExpiredArticleImagePlaceholderSVG : ImagePlaceHolderSVG)}
                    alt={item.variantKey}
                />
                {
                    item.expired ?
                    <WarningTooltipWrapper text={getExpiredTooltipText()} /> :
                    item.discontinued ? <WarningTooltipWrapper text={getDiscontinuedTooltipText()} /> : null
                }
                {   !item.expired &&
                    <div css={styles.colorMark}>
                        <ColorMark color={item.variantColor} size={1} selectedParentRef={ref} />
                    </div>
                }
            </div>

            <ItemDetails item={item} form={form} />
        </div>
    );
};

const styles = {
    removeItemWrapper: css`
        position: absolute;
        left: 0;
        top: 0;
    `,
    removeItem: css`
        left: 10px;
        top: 10px;
        position: absolute;
        cursor: pointer;

        svg {
            width: 13px;
            height: auto;

            path {
                fill: ${colors.grey};
            }
        }
    `,
    wrapper: css`
        display: flex;
        align-items: center;
        position: relative;

        padding-top: 40px;
        padding-left: 32px;
        padding-bottom: 26px;
    `,
    colorMark: css`
        right: 0;
        bottom: 4px;
        position: absolute;

        div {
            padding: 0;
        }
    `,
    discontinuedMarkText: css`
        color: white !important;
    `,
    image: css`
        width: 96px;
        height: 96px;
        min-width: 96px;
        cursor: pointer;
        position: relative;
        margin-right: 19px;
        background-color: ${colors.fontPrimary};

        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        img {
            width: 69px;
        }
    `,
    warnings: css`
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
    `,
    warning: css`
        width: 8px;
        height: 8px;
        margin: 5px;
        background-color: ${colors.warningDot};
        border-radius: 50px;
    `,
};
