import React from 'react';
import { AdditionalItem } from '../../../../../helpers/types';
import { AdditionalItemElement } from './AdditionalItemElement';

type Props = {
    items: AdditionalItem[];
    startingPos: number;
};

export const AdditionalItemsList: React.FC<Props> = ({ items, startingPos }) => (
    <>
        {
            items.map((item, index) =>
                    <AdditionalItemElement key={index} item={item} index={startingPos + index} />
            )
        }
    </>
);
