/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { CustomModal, Header } from 'src/components';
import { OkAndCancelButtons } from 'src/components/Buttons';
import { colors, fontSizes } from 'src/constants/variables';
import { desktopScreenStyles } from 'src/style/styles';

type Props = {
    visible?: boolean;
    onCancel: () => void;
    onConfirm: () => void;
};

export const CancelOfferOverlay: React.FC<Props> = ({ visible = true, onCancel, onConfirm }) => (
    <CustomModal
        visible={visible}
        closable={false}
        maskClosable={false}
        onClick={onCancel}
        modalCss={styles.modal}
    >
        <Header css={styles.header}>ANGEBOTSERSTELLUNG</Header>
        <div css={styles.content}>
            <div css={styles.message}>
                Sind Sie sicher? Ihre Änderungen werden nicht gespeichert.
            </div>
            <OkAndCancelButtons onOk={onConfirm} onCancel={onCancel} customCss={styles.buttons} />
        </div>
    </CustomModal>
);

const styles = {
    modal: css`
        .ant-modal-body,
        .ant-modal-content {
            height: 100%;
        }

        .ant-modal-content {
            padding: 0;
        }
    `,
    header: css`
        && {
            font-size: ${fontSizes.xMedium};
        }
    `,
    content: css`
        display: flex;
        flex-direction: column;
        justify-content: center;

        height: calc(100% - 88px - 84px);
        max-height: 420px;

        color: ${colors.secondary};
        font-size: ${fontSizes.mSmall};
        font-family: Gebauer-Regular, sans-serif;
        line-height: 1.2;
    `,
    message: css`
        text-align: center;
        margin-bottom: 48px;

        ${desktopScreenStyles(css`
            margin-bottom: 90px;
        `)};
    `,
    buttons: css`
        text-align: center;
    `,
};
