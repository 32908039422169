/** @jsxImportSource @emotion/react */
import React from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { dropdownChevronStyles } from 'src/style/styles';
import { colors, fontSizes, shadows } from 'src/constants/variables';

type WeightChooserDropdownProps = {
    weights: number[];
    onWeightChange: (weight: number) => void;
    warning: boolean;
};

export const WeightChooserDropdown: React.FC<WeightChooserDropdownProps> = ({
    weights,
    onWeightChange,
    warning,
}) => {
    const getMenuItems = () =>
        weights.map((value) => (
            <React.Fragment key={value}>
                <Menu.Item
                    css={[styles.item, styles.font]}
                    onClick={() => {
                        onWeightChange(value);
                    }}
                >
                    {value}
                </Menu.Item>
                <Menu.Divider css={styles.divider} />
            </React.Fragment>
        ));

    return (
        <Dropdown
            css={styles.wrapper}
            overlay={<Menu css={styles.list}>{getMenuItems()}</Menu>}
            trigger={['click']}
            getPopupContainer={(trigger) => trigger ?? document.body}
        >
            <div css={[styles.title, styles.font]} data-test={'weightChooserDropdown'}>
                <span>GEWEBE AUSWÄHLEN</span>
                {warning && (
                    <div css={styles.warningContainer}>
                        <div css={styles.warningDot} />
                    </div>
                )}
                <DownOutlined />
            </div>
        </Dropdown>
    );
};

const styles = {
    wrapper: css`
        width: 100%;
        border-top: 1px solid ${colors.secondaryDark};
        border-bottom: 1px solid ${colors.secondaryDark};
        margin-bottom: 16px;
        position: relative;
        cursor: pointer;

        ${dropdownChevronStyles};
    `,
    font: css`
        text-transform: uppercase;
        font-size: ${fontSizes.mSmall};
        font-family: Gebauer-Regular, sans-serif;
    `,
    title: css`
        color: ${colors.fontHeader};
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            color: ${colors.secondary};
        }
    `,
    list: css`
        position: absolute;
        margin-top: 8px;
        width: 100%;
        min-height: 50px;
        background-color: ${colors.primary};
        display: flex;
        flex-direction: column;
        align-items: center;

        border-radius: 5px;
        box-shadow: ${shadows.card};
    `,
    item: css`
        width: 100%;
        padding: 16px 32px;
        color: ${colors.secondary};
    `,
    divider: css`
        width: calc(100% - 32px);
        margin: 0;
        border: 0;
        border-top: 1px solid ${colors.secondaryDark};

        &:last-of-type {
            display: none;
        }
    `,
    warningContainer: css`
        flex-grow: 0.9;
        align-self: baseline;
    `,
    warningDot: css`
        width: 8px;
        height: 8px;
        margin: 5px;
        background-color: ${colors.warningDot};
        border-radius: 50px;
        float: right;
    `,
};
