/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { colors } from 'src/constants/variables';

import { Colors } from './Colors';
import ImagePlaceHolderSVG from 'src/assets/icons/no_image_placeholder.svg';
import { beautifyName } from '../../../../helpers/utils';

type Props = {
    onColorClick: (colorKey: string) => void;
    product: CollectionItem;
    selectedColorKey?: string;
};

export const ProductCardContent: React.FC<Props> = ({
    product,
    onColorClick,
    selectedColorKey,
}) => {
    return (
        <div css={styles.wrapper}>
            <div css={styles.image}>
                <img src={product.image || ImagePlaceHolderSVG} alt={product.modelName} />
            </div>
            <p css={styles.name} data-test={`${beautifyName(product.modelName)}_PRODUCT_NAME`}>
                {product.modelName}
            </p>
            <div data-test={`${beautifyName(product.modelName)}_COLORS`}>
                <Colors
                    colors={product.colors}
                    onClick={onColorClick}
                    selectedColorKey={selectedColorKey}
                />
            </div>
        </div>
    );
};

const styles = {
    loaderContainer: css`
        position: relative;
    `,
    spinnerContainer: css`
        position: absolute;
        top: 10px;
        left: 30px;
    `,
    wrapper: css`
        width: 100%;
        display: inline-block;
        margin-bottom: 20px;

        .ant-carousel {
            display: flex;
            justify-content: center;
        }
    `,
    image: css`
        display: flex;
        width: 100%;
        padding: 40px 16px 16px;
        border-radius: 5px;
        background-color: ${colors.primary};

        & > img {
            width: 100%;
            aspect-ratio: 1;
            align-content: stretch;
        }
    `,
    name: css`
        display: flex;
        flex-direction: column;
        height: 60px;
        margin: 0;
        padding: 16px 0 16px;

        color: ${colors.fontSecondaryDark};
        font-family: Gebauer-Regular, serif;
        line-height: 1;
        text-transform: uppercase;
        text-align: center;
    `,
};
