import React from 'react';
import { colors } from 'src/constants/variables';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

export const pageStyles = StyleSheet.create({
    section: {
        position: 'relative',
        minHeight: '100%',
        height: '100%',
    },
    page: {
        padding: '40px',
        fontSize: '11px',
        fontFamily: 'GebauerRegular',
    },
});
const styles = StyleSheet.create({
    pageTitle: {
        fontSize: '8px',
        margin: '0 0 56px',
        fontFamily: 'GebauerMedium',
        color: `${colors.darkRed}`,
    },
});

export const PageTitle: React.FC = () => (
    <View>
        <Text style={styles.pageTitle}>IHR PERSÖNLICHES ANGEBOT</Text>
    </View>
);
