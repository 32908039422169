/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { colors, fontSizes } from 'src/constants/variables';

type Props = {
    title: string;
    price: string | number;
};

export const OfferPreviewDetailsTotalPrice: React.FC<Props> = ({ title, price }) => (
    <div css={offerPreviewDetailsElementStyles.namePriceContainer}>
        <div css={offerPreviewDetailsElementStyles.nameLabel}>{title}</div>
        <div css={offerPreviewDetailsElementStyles.price}>{price}</div>
    </div>
);

export const offerPreviewDetailsElementStyles = {
    price: css`
        color: ${colors.fontHeader};
        font-family: 'Gebauer-Medium', sans-serif;
        font-size: ${fontSizes.xLarge};
    `,
    nameLabel: css`
        text-align: right;
        width: 93%;
    `,
    namePriceContainer: css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;

        font-size: ${fontSizes.large};
        letter-spacing: 0;
        font-family: 'Gebauer-Regular', sans-serif;
        
        padding-top: 16px;
    `,
};
