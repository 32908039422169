import React from 'react';
import { Text, StyleSheet, Image, View } from '@react-pdf/renderer';
import { DeliveryCycleDetails, DeliveryOptionData } from '../../../types';

import JacketIcon from 'src/assets/icons/jacket.png';
import Calendar from 'src/assets/icons/calendar.png';
import Sigma from 'src/assets/icons/sigma.png';

const styles = StyleSheet.create({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        padding: '20px 10px',
        fontSize: '8px',
        fontFamily: 'GebauerRegular',
    },
    leftPanel: {
        position: 'relative',
        height: '100%',
        width: '33%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '24px',
    },
    leftPanelHeader: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '12px',
    },
    icon: {
        width: '18px',
        height: '18px',
        position: 'relative',
        marginRight: '6px',
    },
    redMedium: {
        fontFamily: 'GebauerMedium',
        color: '#E60021',
    },
    smallIcon: {
        width: '8px',
        height: '8px',
        paddingRight: '2px',
    },
    leftInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    panelIcons: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
    },
    rightPanel: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    borderBottom: {
        borderBottom: '1px solid #eaeaea',
    },
});

type Props = {
    cycleLength: number;
    period: number;
    data: DeliveryOptionData;
    addBorderBottom?: boolean;
};

function getIcons(count: number) {
    const icons = [];

    for (let i = 0; i < count; ++i) {
        icons.push(<Image key={i} style={styles.icon} src={JacketIcon} />);
    }

    return icons;
}

function getDetails(data: DeliveryCycleDetails[]) {
    const details = []

    for (let i = 0; i < data.length; ++i) {
        details.push(
            <div key={i} style={i < data.length - 1 ? { paddingBottom: '12px' } : {}}>
                <Text style={{ fontFamily: 'GebauerMedium' }}>
                    <span style={styles.redMedium}>{data[i].number}</span>  {data[i].mainText}
                </Text>

                <Text>{data[i].text}</Text>
            </div>
        );
    }

    return details
}

export const DeliveryOption: React.FC<Props> = ({ cycleLength, period, data, addBorderBottom }) => (
    <View style={{ ...styles.container, ...(addBorderBottom ? styles.borderBottom : {}) }}>
        <View style={styles.leftPanel}>
            <View style={styles.leftPanelHeader}>
                <div style={styles.panelIcons}>
                    {getIcons(data.iconCount)}
                </div>

                <div>
                    <Text style={{ fontFamily: 'GebauerMedium' }}>
                        <span style={styles.redMedium}>{data.iconCount}</span>  {data.iconCount === 1 ? 'Kleidungsteil' : 'Kleidungsteile'}
                    </Text>
                    <Text>pro Woche</Text>
                </div>
            </View>

            <View>
                <div style={styles.leftInfo}>
                    <Image src={Calendar} style={styles.smallIcon} />
                    <Text>{cycleLength}-tägiger Lieferrhythmus</Text>
                </div>

                <div style={styles.leftInfo}>
                    <Image src={Sigma} style={styles.smallIcon} />
                    <Text>Insgesamt {period} Kleidungsteile</Text>
                </div>
            </View>
        </View>

        <div style={styles.rightPanel}>
            {getDetails(data.rightPanel)}
        </div>
    </View>
);
