/** @jsxImportSource @emotion/react */
import React from 'react';
import { WarningTooltip } from '../WarningTooltip';
import { colorMarkStyles } from '../ColorMark/colorMarkStyles';
import { css } from '@emotion/react';

export interface WarningTooltipWrapperProps {
    text: string
}

export const WarningTooltipWrapper: React.FC<WarningTooltipWrapperProps> = ({text}) => <div css={styles.discontinuedOrExpiredMark}>
        <WarningTooltip text={text}>
            <div>
                <div
                    css={[
                        colorMarkStyles.mark([184, 33, 33], 1),
                        colorMarkStyles.circle(1),
                        colorMarkStyles.mainCircle,
                        colorMarkStyles.textColor,
                    ]}
                >
                    !
                </div>
            </div>
        </WarningTooltip>
    </div>

const styles = {
    discontinuedOrExpiredMark: css`
        left: 1px;
        top: 1px;
        position: absolute;
    `,
}