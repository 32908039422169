import { httpService } from 'src/modules/app/service/httpService';
import { endpoints } from 'src/constants/variables';

async function fetchCollectionItemsList(collectionId: string): Promise<CollectionData> {
    const result = await httpService<CollectionData>({ url: endpoints.collection(collectionId) });
    return transformItemImages(result);
}

// Transform TIFF -> JPG
function transformItemImages(collection: CollectionData): CollectionData {
    const modelInfos: CollectionItem[] = collection.modelInfos.map((modelInfo) => ({
        ...modelInfo,
        image: modelInfo.image,
    }));
    return { ...collection, modelInfos };
}

export const productCollectionItemsService = { fetchCollectionItemsList };
