/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { colors, fontSizes } from 'src/constants/variables';

type Props = {
    title: string[];
    first?: boolean;
};

export const ColumnTitle: React.FC<Props> = ({ title, first }) => (
    <div css={styles.wrapper}>
        {title.map((row, i) => <p key={i} css={[styles.content, first && styles.indexColumn]}>{row}</p>)}
    </div>
);

const styles = {
    wrapper: css`
        background-color: ${colors.primary};
        height: 100%;
        font-size: ${fontSizes.xxSmall};
        font-family: 'Gebauer-Medium', sans-serif;
        font-weight: 900;
        padding-bottom: 18px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: right;
    `,
    content: css`
        margin: 0;
    `,
    indexColumn: css`
        display: flex;
        justify-content: flex-start;
        padding-left: 6px;
    `,
};
