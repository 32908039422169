/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { preparePriceFromDetails } from './preparePriceFromDetails';
import { OfferPreviewDetailsElement } from './OfferPreviewDetailsElement';
import { OfferPreviewDetailsTotalPrice } from './OfferPreviewDetailsTotalPrice';

type Props = {
    details?: OfferDetails;
    services: WardrobeOption[];
};

export const OfferPreviewDetails: React.FC<Props> = ({ details, services }) => (
    <div css={styles.wrapper}>
        <OfferPreviewDetailsElement
            price={preparePriceFromDetails('deliveryPricePerWeek', details)}
            title="Lieferpauschale pro Woche je Lieferstelle"
        />

        {
            details?.assemblyFee &&
            <OfferPreviewDetailsElement
                price={preparePriceFromDetails('assemblyPrice', details)}
                title="Konfektionsgebühr"
                isBold
            />
        }

        {details?.totalPricePerWeek && (
            <OfferPreviewDetailsTotalPrice
                price={preparePriceFromDetails('totalPricePerWeekValue', details)}
                title="Wöchentlicher Gesamtpreis"
            />
        )}
    </div>
);

const styles = {
    wrapper: css`
        margin-top: 52px;
        padding: 10px 40px;
        background-color: #F9F9F9;
        width: 100%;
    `,
};
