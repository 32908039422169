const colorMarks: RGBValuesList[][] = [
    [[26, 141, 108]],
    [[27, 35, 42]],
    [
        [113, 100, 84],
        [27, 35, 42],
    ],
    [[255, 255, 255]],
    [
        [255, 255, 255],
        [150, 158, 166],
    ],
    [[167, 142, 114]],
    [
        [35, 140, 109],
        [123, 124, 130],
    ],
    [[150, 158, 166]],
    [
        [150, 158, 166],
        [123, 124, 130],
    ],
    [[22, 43, 72]],
    [
        [22, 43, 72],
        [27, 35, 42],
    ],
    [[54, 99, 180]],
    [
        [54, 99, 180],
        [27, 35, 42],
    ],
    [
        [212, 5, 38],
        [123, 124, 130],
    ],
];

export const NO_COLOR_RGB_PLACEHOLDER: RGBValuesList = [217, 217, 217];

const names = [
    'efeugruen',
    'anthrazit',
    'nougat / anthrazit',
    'weiss',
    'weiss / malachitgrau',
    'safaribeige',
    'efeugruen / anthrazit',
    'malachitgrau',
    'malachitgrau / anthrazit',
    'marineblau',
    'marineblau / anthrazit',
    'meerblau',
    'meerblau / anthrazit',
    'poppyrot / anthrazit',
];

export const productColors: ProductColor[] = names.map((name, index) => ({
    key: index.toString(),
    name,
    rgbList: colorMarks[index],
    imageLink: "",
    variantKey: "",
    discontinued: false,
}));
