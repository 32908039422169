/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';

import { Checkbox } from 'src/components/FormElements';
import { colors } from 'src/constants/variables';

import { ProductCardContent } from './ProductCardContent';
import { beautifyName } from '../../../../helpers/utils';

type Props = {
    defaultVariant: CollectionItem;
    addToSelectedProducts: (item: CollectionItem) => void;
    selectedProducts: CollectionItem[];
    setSelectedProducts: (items: CollectionItem[]) => void;
    getDetailsLink?: (item: CollectionItem) => string;
};

export const ProductCard: React.FC<Props> = ({
    defaultVariant,
    selectedProducts,
    setSelectedProducts,
    addToSelectedProducts,
    getDetailsLink,
}) => {
    const [curVariant, setCurVariant] = useState(defaultVariant);
    const itemDetailsLink = getDetailsLink?.(curVariant);
    const showItemDetailsLink = !!itemDetailsLink;

    const onColorClick = (colorKey: string) => {
        const newColor = curVariant.colors.find(color => color.key === colorKey);

        const updatedProducts = selectedProducts.map(selectedProduct => {
            if (selectedProduct.modelId === curVariant.modelId && selectedProduct.variantKey === curVariant.variantKey
                && selectedProduct.selectedColorKey === curVariant.selectedColorKey) {
                const updatedProduct = selectedProduct;

                updatedProduct.variantKey = newColor?.variantKey || "";
                updatedProduct.selectedColorKey = colorKey;
                updatedProduct.image = newColor?.imageLink || "";

                return updatedProduct;
            }

            return selectedProduct;
        });
        setSelectedProducts(updatedProducts);

        setCurVariant((curVariant) => ({
                modelId: curVariant.modelId || "",
                modelName: curVariant.modelName || "",
                variantKey: newColor?.variantKey || "",
                selectedColorKey: newColor?.key || "",
                image: newColor?.imageLink || "",
                colors: curVariant.colors,
            }));
    }

    return (
        <>
            <div
                css={styles.checkboxWrapper}
                data-test={`${beautifyName(curVariant.modelName)}_ADD_BUTTON`}
            >
                <Checkbox
                    formItemCss={styles.checkboxInput}
                    checked={!!selectedProducts.find((item) => item.modelId === curVariant.modelId)}
                    onClick={() => addToSelectedProducts(curVariant)}
                />
            </div>
            {showItemDetailsLink && itemDetailsLink ? (
                <Link to={itemDetailsLink}>
                    <ProductCardContent
                        product={curVariant}
                        onColorClick={onColorClick}
                        selectedColorKey={curVariant.selectedColorKey}
                    />
                </Link>
            ) : (
                <ProductCardContent
                    product={curVariant}
                    onColorClick={onColorClick}
                    selectedColorKey={curVariant.selectedColorKey}
                />
            )}
        </>
    );
};

const styles = {
    checkboxWrapper: css`
        position: absolute;
        top: 12px;
        right: 18px;

        svg {
            width: 22px;
            height: auto;
        }
    `,
    checkboxInput: css`
        && .ant-checkbox {
            width: 22px;
            height: 22px;
            padding: 2px;
            border-radius: 5px;
            border-color: ${colors.fontPrimaryDark};
        }

        && .ant-checkbox-inner {
            width: 16px;
            height: 16px;
            border-radius: 3px;
        }
    `,
};
