/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { CustomModal, Header } from 'src/components';
import { colors, fontSizes } from 'src/constants/variables';
import { desktopScreenStyles } from 'src/style/styles';
import { Tick } from 'src/assets/icons';

type Props = {
    visible?: boolean;
    onClick: () => void;
};

export const BrowserWarningOverlay: React.FC<Props> = ({ visible = true, onClick }) => (
    <CustomModal
        visible={visible}
        closable={false}
        maskClosable={false}
        onClick={onClick}
        modalCss={styles.modal}
    >
        <Header css={styles.header}>BROWSER KOMPATIBILITÄT</Header>
        <div css={styles.content}>
            <div css={styles.message}>
                Der von Ihnen verwendete Browser wird nicht unterstützt, sodass Probleme bei der
                Nutzung des Angebotsportals auftreten können. Wir empfehlen, einen der folgenden
                Browser zu verwenden, um eine bessere Nutzung zu ermöglichen:
                <ul css={styles.list}>
                    <li>Google Chrome (Version 109 oder neuere Version)</li>
                    <li>Microsoft Edge (Version 108 oder neuere Version)</li>
                </ul>
            </div>
            <div css={styles.buttons}>
                <Button type={'text'} htmlType={'button'} onClick={onClick}>
                    <Icon component={Tick} css={styles.iconOk} />
                </Button>
            </div>
        </div>
    </CustomModal>
);

const styles = {
    buttons: css`
        text-align: center;
        button {
            padding: 0;
            height: auto;

            &:not(:last-of-type) {
                margin-right: 25px;
            }
        }
        svg {
            height: 18px;
        }
    `,
    modal: css`
        .ant-modal-body,
        .ant-modal-content {
            height: 100%;
        }
        .ant-modal-content {
            padding: 0;
        }
    `,
    header: css`
        && {
            font-size: ${fontSizes.xMedium};
        }
    `,
    content: css`
        display: flex;
        flex-direction: column;
        justify-content: center;

        height: calc(100% - 88px - 84px);
        max-height: 420px;

        color: ${colors.secondary};
        font-size: ${fontSizes.mSmall};
        font-family: Gebauer-Regular, sans-serif;
        line-height: 1.2;
    `,
    message: css`
        text-align: center;
        margin-bottom: 48px;

        ${desktopScreenStyles(css`
            margin-bottom: 90px;
        `)};
    `,
    list: css`
        list-style-type: none;
        padding-top: 15px;
    `,
    iconOk: css`
        svg {
            width: 24px;
            polygon {
                fill: ${colors.confirm};
            }
        }
    `,
};
