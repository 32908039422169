/** @jsxImportSource @emotion/react */
import React from 'react';
import { NumberInput, simpleInputCss } from 'src/components/DataInputs';
import { MAX_PRICE_VALUE } from 'src/constants';

type Props = {
    value?: number;
    onChange?: (value?: number) => void;
    required?: boolean;
    allowEmpty?: boolean;
    disabled?: boolean;
};

export const BasePriceField: React.FC<Props> = ({ value, onChange, required, allowEmpty, disabled }) => (
    <NumberInput
        min={0.0}
        placeholder="0,00"
        max={MAX_PRICE_VALUE}
        step={0.01}
        precision={2}
        value={value}
        onChange={onChange}
        currencySuffix
        css={simpleInputCss}
        addComma
        allowEmpty={allowEmpty}
        required={required}
        disabled={disabled}
    />
);
