import React from 'react';
import { Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';

import OutroPageBg from 'src/assets/images/pdfBackgrounds/outro-bg.png';
import { colors } from '../../../../constants/variables';

import { PageNumber } from 'src/modules/offerPdf/components/pageNumber';

const styles = StyleSheet.create({
    section: {
        position: 'relative',
        minHeight: '100%',
        height: '100%',
    },
    pageBackground: {
        position: 'absolute',
        width: '595px',
        height: '420px',
    },
    greetingContainer: {
        position: 'absolute',
        left: '64px',
        top: '448px',
    },
    greetingText: {
        fontSize: '11px',
        paddingTop: '18px',
        maxWidth: '82%',
        fontFamily: 'GebauerRegular',
        color: colors.darkGrey,
    },
});

type Props = {
    text: string;
    pageOffset: number;
};

export const OutroPage: React.FC<Props> = ({ text, pageOffset }) => (
    <Page size="A4">
        <View style={styles.section}>
            <Image style={styles.pageBackground} src={OutroPageBg} />

            <div style={styles.greetingContainer}>
                <Text style={styles.greetingText}>{text.split('')}</Text>
            </div>

            <PageNumber offset={pageOffset} />
        </View>
    </Page>
);
