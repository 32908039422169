export const DELIVERY_7T = {
    length: 7,
    periods: [3, 5, 7],
    option1: {
        iconCount: 1,
        rightPanel: [
            {
                number: 1,
                mainText: 'Kleidungsteil',
                text: 'wird von Ihnen getragen',
            },
            {
                number: 1,
                mainText: 'Kleidungsteil',
                text: 'wird von Mewa gepflegt',
            },
            {
                number: 1,
                mainText: 'Reserve Kleidungsteil',
                text: 'ist bei Ihnen als Reserve vor Ort',
            },
        ],
    },
    option2: {
        iconCount: 2,
        rightPanel: [
            {
                number: 2,
                mainText: 'Kleidungsteile',
                text: 'werden von Ihnen getragen',
            },
            {
                number: 2,
                mainText: 'Kleidungsteile',
                text: 'werden von Mewa gepflegt',
            },
            {
                number: 1,
                mainText: 'Reserve Kleidungsteil',
                text: 'ist bei Ihnen als Reserve vor Ort',
            },
        ],
    },
    option3: {
        iconCount: 3,
        rightPanel: [
            {
                number: 3,
                mainText: 'Kleidungsteile',
                text: 'werden von Ihnen getragen',
            },
            {
                number: 3,
                mainText: 'Kleidungsteile',
                text: 'werden von Mewa gepflegt',
            },
            {
                number: 1,
                mainText: 'Reserve Kleidungsteil',
                text: 'ist bei Ihnen als Reserve vor Ort',
            },
        ],
    },

};

export const DELIVERY_14T = {
    length: 14,
    periods: [5, 9, 13],
    option1: {
        iconCount: 1,
        rightPanel: [
            {
                number: 1,
                mainText: 'Kleidungsteil',
                text: 'wird von Ihnen in Woche 1 getragen',
            },
            {
                number: 1,
                mainText: 'Kleidungsteil',
                text: 'wird von Ihnen in Woche 2 getragen',
            },
            {
                number: 2,
                mainText: 'Kleidungsteile',
                text: 'werden von Mewa gepflegt',
            },
            {
                number: 1,
                mainText: 'Reserve Kleidungsteil',
                text: 'ist bei Ihnen als Reserve vor Ort',
            },
        ],
    },
    option2: {
        iconCount: 2,
        rightPanel: [
            {
                number: 2,
                mainText: 'Kleidungsteile',
                text: 'werden von Ihnen in Woche 1 getragen',
            },
            {
                number: 2,
                mainText: 'Kleidungsteile',
                text: 'werden von Ihnen in Woche 2 getragen',
            },
            {
                number: 4,
                mainText: 'Kleidungsteile',
                text: 'werden von Mewa gepflegt',
            },
            {
                number: 1,
                mainText: 'Reserve Kleidungsteil',
                text: 'ist bei Ihnen als Reserve vor Ort',
            },
        ],
    },
    option3: {
        iconCount: 3,
        rightPanel: [
            {
                number: 3,
                mainText: 'Kleidungsteile',
                text: 'werden von Ihnen in Woche 1 getragen',
            },
            {
                number: 3,
                mainText: 'Kleidungsteile',
                text: 'werden von Ihnen in Woche 2 getragen',
            },
            {
                number: 6,
                mainText: 'Kleidungsteile',
                text: 'werden von Mewa gepflegt',
            },
            {
                number: 1,
                mainText: 'Reserve Kleidungsteil',
                text: 'ist bei Ihnen als Reserve vor Ort',
            },
        ],
    },
};
