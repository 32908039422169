/** @jsxImportSource @emotion/react */
import React from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { dropdownChevronStyles } from 'src/style/styles';
import { colors, fontSizes, shadows } from 'src/constants/variables';

import { CollectionSelectorProps } from './types';

export const CollectionsDropdown: React.FC<CollectionSelectorProps> = ({
    collectionsList = [],
    currentCollectionId,
    onCollectionSelect,
}) => {
    const currentCollection = collectionsList.find(
        (collection) => collection.id === currentCollectionId,
    );

    const getMenuItems = () =>
        collectionsList
            .filter((collection) => !collection.disabled && collection.id !== currentCollectionId)
            .map((collection) => (
                <React.Fragment key={collection.id}>
                    <Menu.Item
                        css={[styles.item, styles.font]}
                        onClick={() => onCollectionSelect?.(collection)}
                    >
                        {collection.name}
                    </Menu.Item>
                    <Menu.Divider css={styles.divider} />
                </React.Fragment>
            ));

    return (
        <Dropdown
            css={styles.wrapper}
            overlay={<Menu css={styles.list}>{getMenuItems()}</Menu>}
            trigger={['click']}
            getPopupContainer={(trigger) => trigger ?? document.body}
        >
            <div css={[styles.title, styles.font]}>
                <span>{currentCollection?.name}</span>
                <DownOutlined />
            </div>
        </Dropdown>
    );
};

const styles = {
    wrapper: css`
        width: 100%;
        border-top: 1px solid ${colors.primary};
        border-bottom: 1px solid ${colors.primary};
        margin-bottom: 16px;
        position: relative;

        .ant-dropdown::before {
            left: 0;
            bottom: -13px;
            opacity: 97%;
            background: ${colors.background};
        }

        ${dropdownChevronStyles};
    `,
    font: css`
        text-transform: uppercase;
        font-size: ${fontSizes.mSmall};
        font-family: Gebauer-Regular, sans-serif;
    `,
    title: css`
        color: ${colors.fontHeader};
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            color: ${colors.secondary};
        }
    `,
    list: css`
        position: absolute;
        margin-top: 8px;
        width: 100%;
        min-height: 50px;
        background-color: ${colors.primary};
        display: flex;
        flex-direction: column;
        align-items: center;

        border-radius: 5px;
        box-shadow: ${shadows.card};
    `,
    item: css`
        width: 100%;
        padding: 16px 32px;
        color: ${colors.secondary};
    `,
    divider: css`
        width: calc(100% - 32px);
        margin: 0;
        border: 0;
        border-top: 1px solid ${colors.secondaryDark};

        &:last-of-type {
            display: none;
        }
    `,
};
