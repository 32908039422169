import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    bottomPageNumber: {
        position: 'absolute',
        bottom: '25px',
        right: '40px',
        fontSize: '8px',
        fontFamily: 'GebauerBold',
    },
});

type Props = {
    offset: number;
}

export const PageNumber: React.FC<Props> = ({ offset }) => (
    <Text render={({ pageNumber }) => `${offset + pageNumber}`} style={styles.bottomPageNumber} fixed />
);
